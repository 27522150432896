import { useFormik } from "formik";
import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../redux/Actions/contract.action";
import { getXTokenInformation } from "../../../../services/axios.service";
import {
  convertWithDecimal,
  customizeAddress,
  divideBigNumber,
  divideBigNumberWithSuffixes,
} from "../../../../services/common.service";
import { getBorrowAndSupplyDetails } from "../../../../services/contract.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import { ZERO_ADDRESS } from "../../../../utils/Constant";
import { Yup } from "../../../../utils/Utils";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import CommonTable from "../../commonTable/CommonTable";
import CustomSelect from "../../customSelect/CustomSelect";
import toaster from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./BorrowersModal.scss";
interface propTypes {
  data?: any;
  title?: string;
}

const BorrowersModal = ({ data, title }: propTypes) => {
  const [allTokens, setAllTokens] = useState<any>();
  const [show, setShow] = useState(false);
  const [closeFactorMantissa, setCloseFactorMantissa] = useState<any>(0);
  const [selectedData, setSelectedData] = useState<any>();
  const [optionData, setOptionsData] = useState<any>();
  const [supplyInformaiton, setSupplyInformation] = useState<any>();
  const { connector } = useAccount();
  const dispatch: Dispatch<any> = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );

  const thead: any = [
    { label: "Token" },
    { label: "Borowed Amount" },
    { label: "Action" },
  ];

  const getCloseFactorMantisa = async () => {
    let result: any = await dispatch(
      callContractGetMethod("closeFactorMantissa", [], "comptroller")
    );

    if (result) {
      setCloseFactorMantissa(divideBigNumber(result, 16));
    }
  };
  const formik = useFormik({
    initialValues: {
      repayAmount: 0,
      xTokenCollateral: "",
    },
    validationSchema: Yup.object({
      repayAmount: Yup.number()
        .required("Required*")
        .moreThan(0, "Value should be greater than 0"),

      xTokenCollateral: Yup.string().required("Required*"),
    }),
    onSubmit: (values) => {
      liquidateBorrow(values);
    },
  });

  const liquidateBorrow = async (values: any) => {
    try {
      let provider = await connector?.getProvider();
      let borrowedAmt = divideBigNumber(
        selectedData.borrowInfo,
        String(selectedData.tokenInfo.decimals).length - 1 || 1
      );
      let percentVal = (values.repayAmount / borrowedAmt) * 100;
      if (closeFactorMantissa !== 0 && percentVal > closeFactorMantissa) {
        toaster.error(
          `You can not liquidate more than ${closeFactorMantissa}% of Borrow amount`
        );
      }

      setShowConfirmation(true);
      setModalKey("liquidate");
      handleClose();
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "liquidateBorrow",
          selectedData?.tokenInfo.address === ZERO_ADDRESS
            ? [data.userAddress, values.xTokenCollateral]
            : [
                data.userAddress,
                Number(
                  convertWithDecimal(
                    values.repayAmount,
                    String(selectedData?.tokenInfo.decimals).length - 1 || 1
                  )
                ),
                values.xTokenCollateral,
              ],
          walletAddress,
          selectedData?.tokenInfo.address === ZERO_ADDRESS
            ? "cether"
            : "cerc20",
          "liquidate",
          "",
          selectedData.address,
          selectedData?.tokenInfo.address,
          selectedData?.tokenInfo.address === ZERO_ADDRESS ? true : false
        )
      );
      if (result?.status) {
        handleClose();
      } else if (result === undefined) {
        setModalKey("error");
      }
    } catch (error: any) {
      console.error("Error in function calling:", error);
      toaster.error("Error in function calling. Kindlly verify details.");
    }
  };

  const getAllMarketDetails = async () => {
    let result: any = await dispatch(
      callContractGetMethod("getAllMarkets", [], "comptroller")
    );
    if (result) {
      setAllTokens(result);
      const supplyAndBorrwDetial = await getBorrowAndSupplyDetails(
        result,
        data.userAddress
      );
      const xTokenInfo = await getXTokenInformation(supplyAndBorrwDetial);
      setAllTokens(xTokenInfo);
    }
  };

  const handleShow = (showState: boolean) => {
    setShow(showState);
    getAllMarketDetails();
    getCloseFactorMantisa();
  };
  const handleClose = () => {
    setShow(false);
    setAllTokens("");
    setSelectedData("");
    setSupplyInformation("");
    formik.resetForm();
  };

  const handleLiquidate = (info: any) => {
    setSelectedData(info);
    setSupplyInfo();
  };

  const setSupplyInfo = () => {
    const value = allTokens.filter((item: any) => item.supplyInfo != 0);
    const options = value.map((item: any) => {
      return {
        value: item.address,
        label: (
          <span>
            <img
              src={item.tokenInfo.logo}
              alt="Currency-Logo"
              className="currencyLogo"
              style={{ width: "20px", height: "20px", marginRight: "5px" }} // Optional styling
            />
            {item.tokenInfo.symbol}
          </span>
        ),
      };
    });

    setOptionsData(options);
  };

  const handleSupplyInformation = (add: string) => {
    const value = allTokens.filter((item: any) => item.address == add);
    setSupplyInformation(value[0]);
  };

  return (
    <>
      {data.outputs[2] && (
        <Button
          // disabled={!data.outputs[2]}
          className="setCollateralBtn"
          onClick={() => handleShow(true)}
        >
          {title}
        </Button>
      )}
      <CommonModal
        show={show}
        onHide={handleClose}
        title={"Liquidate Token"}
        className="confirm_logout borrow_modal" 
      >
        {!selectedData && (
          <div className="table_sec">
            <CommonTable fields={thead}>
              {allTokens &&
                allTokens?.map(
                  (item: any) =>
                    item.borrowInfo && (
                      <tr>
                        <td>
                          <img src={item.tokenInfo?.logo} alt="" />
                          {item.tokenInfo?.symbol}
                        </td>

                        <td>
                          {divideBigNumberWithSuffixes(
                            item.borrowInfo,
                            String(item.tokenInfo.decimals).length - 1 || 1,
                            true
                          )}
                        </td>

                        <td>
                          <Button
                            className="setCollateralBtn"
                            onClick={() => handleLiquidate(item)}
                          >
                            Liquidate
                          </Button>
                        </td>
                      </tr>
                    )
                )}
            </CommonTable>
          </div>
        )}
        {selectedData && (
          <>
            <h3>Borrow Info</h3>
            <ul>
              <li>
                <img src={selectedData?.tokenInfo.logo} alt="" />
                {selectedData?.tokenInfo.name}
              </li>
              <li>
                {selectedData?.tokenInfo.xToken &&
                  customizeAddress(selectedData?.tokenInfo.xToken)}
              </li>
              <li>
                {" "}
                {divideBigNumberWithSuffixes(
                  selectedData.borrowInfo,
                  String(selectedData.tokenInfo.decimals).length - 1 || 1,
                  true
                )}{" "}
                {selectedData?.tokenInfo.symbol}
              </li>
            </ul>

            <form onSubmit={formik.handleSubmit}>
              <div>
              <InputCustom
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={"Repay Amount "}
                value={
                  formik.values.repayAmount ? formik.values.repayAmount : ""
                }
                type="number"
                maxLength={25}
                placeholder={"Enter Value here"}
                name="repayAmount"
                error={formik.touched.repayAmount && formik.errors.repayAmount}
              />
              <h4 className="warning-text">
                You can not repay more than
                50% of borrowed amount
              </h4>
              </div>
              <CustomSelect
                label=" Select Interest rate"
                onChange={(option: any) => {
                  formik.values.xTokenCollateral = option.value;
                  handleSupplyInformation(option.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.xTokenCollateral}
                name="xTokenCollateral"
                options={optionData}
                error={
                  formik.touched.xTokenCollateral &&
                  formik.errors.xTokenCollateral
                }
              />
              {supplyInformaiton && (
                <>
                  <h3>Supply Information</h3>
                  <ul>
                    <li>
                      <img src={supplyInformaiton?.tokenInfo.logo} alt="" />
                      {supplyInformaiton?.tokenInfo.name}
                    </li>
                    <li>
                      {supplyInformaiton?.address &&
                        customizeAddress(supplyInformaiton?.address)}
                    </li>
                    <li>
                      {" "}
                      {divideBigNumberWithSuffixes(
                        supplyInformaiton.supplyInfo,
                        String(supplyInformaiton.tokenInfo.decimals).length -
                          1 || 1,
                        true
                      )}{" "}
                      {supplyInformaiton?.tokenInfo.symbol}
                    </li>
                  </ul>
                </>
              )}

              <div className="btn_pair">
                <Button type="submit">Confirm</Button>
                <Button onClick={handleClose} className="border_btn">
                  Cancel
                </Button>
              </div>
            </form>
          </>
        )}
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default BorrowersModal;
