import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { Toaster } from "react-hot-toast";
import { WagmiProvider, createConfig, http } from "wagmi";
import { bscTestnet } from "wagmi/chains";
import { injected, walletConnect } from "wagmi/connectors";
import Application from "./Application";
import { PROJECT_ID } from "./utils/Constant";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 2. Create wagmiConfig
const metadata = {
  name: "Collatix",
  description: "Collatix",
  url: "https://stage.collatix.com/", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

export const wagmiConfig = createConfig({
  chains: [bscTestnet],
  connectors: [
    injected(),
    walletConnect({
      projectId: PROJECT_ID,
      showQrModal: false,
      metadata,
    }),
  ],
  transports: {
    [bscTestnet.id]: http(),
  },
});

// 3. Create modal
createWeb3Modal({
  themeMode: "dark",
  wagmiConfig,
  projectId: PROJECT_ID,
  themeVariables: {
    "--w3m-accent": "#DA41DD",
    "--w3m-font-family": "Montserrat', sans-serif",
    "--w3m-border-radius-master": "2px",
    "--w3m-font-size-master": "20px",
    "--w3m-color-mix": "#620b63",
    "--w3m-color-mix-strength": 10,
    "--w3m-z-index": 999,
  },
});

const App = () => {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        {/* <Loader /> */}
        <Toaster />
        <Application />
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
