// /** @format */

import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Web3 from "web3";
import toaster from "../../components/common/toast";
import { getError } from "../../services/common.service";
import {
  callGetMethod,
  callSendMethod,
  callWeb3,
  createInstance,
} from "../../services/contract.service";
import { buttonLoader, loader } from "../Slices/loader.slice";

/**CALL COONTRACT'S GET METHODS */
export const callContractGetMethod = (
  method: string,
  data: any = [],
  contractType: string,
  loading = true,
  dynamicAddress = "",
  showError = true
) => {
  return async (dispatch: Dispatch<any> = useDispatch()) => {
    try {
      /**SHOW LOADING */
      if (loading) dispatch(loader(true));
      /**CALL GET METHOD */
      const result = await callGetMethod(
        method,
        data,
        contractType,
        dynamicAddress
      );
      if (loading) dispatch(loader(false));
      return result;
    } catch (error) {
      console.log("error", error);
      if (loading) dispatch(loader(false));
      return showError ? toaster.error(getError(error)) : null;
    }
  };
};

/**CALL COONTRACT'S SEND METHODS */
export function callContractSendMethod(
  provider: any,
  method: string,
  data: any = [],
  walletAddress: string,
  contractType: string,
  buttonLoaderKey: string = "",
  value = "",
  dynamicAddress = "",
  tokenAddress = "",
  isNative = false
) {
  // console.log(
  //   "first",
  //   provider,
  //   method,
  //   data,
  //   walletAddress,
  //   contractType,
  //   buttonLoaderKey,
  //   value,
  //   dynamicAddress,
  //   tokenAddress,
  //   isNative
  // );
 console.log('data', data)
  return async (dispatch: Dispatch<any> = useDispatch()) => {
    try {
      dispatch(buttonLoader({ [buttonLoaderKey]: true }));
      /**CREATE INSTANCE WITH WALLET */
      const contractInstance = await createInstance(provider);
      if (contractInstance) {
        /**CALL SEND METHOD */
        const result = await callSendMethod(
          provider,
          method,
          data,
          walletAddress,
          contractType,
          value,
          dynamicAddress,
          tokenAddress,
          isNative
        );
        dispatch(buttonLoader({ [buttonLoaderKey]: false }));

        return result;
      } else {
        /**IF ANY ERROR IN CREATING INSTANCE */
        dispatch(buttonLoader({ [buttonLoaderKey]: false }));

        return toaster.error(
          "Some error occurred during contract interaction. Please reload the page."
        );
      }
    } catch (error) {
      console.log('error', error)
      dispatch(buttonLoader({ [buttonLoaderKey]: false }));

      return toaster.error(getError(error));
    }
  };
}

export const validateAddress = (address: string, type: string) => {
  return async () => {
    try {
      let isAddress = Web3.utils.isAddress(address);
      if (isAddress) {
        let web3: any = await callWeb3("");
        let check = await web3.eth.getCode(address);
        if (
          (check !== "0x" && type === "wallet") ||
          (check === "0x" && type === "contract")
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
