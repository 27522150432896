import moment from "moment";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CopyIcon, RedirectIcon } from "../../../assets/icons/Icons";
import useCopyClipboard from "../../../hooks/useCopyToClipboard";
import { callApiGetMethod } from "../../../redux/Actions/api.action";
import { loader } from "../../../redux/Slices/loader.slice";
import {
  customizeAddress,
  divideBigNumber,
  intToSuffixes,
} from "../../../services/common.service";
import { RootState } from "../../../Types/reduxStateType/RootState";
import { DATE_FORMAT, TABLE_LIMIT } from "../../../utils/Utils";
import "../../common/adminHeader/AdminHeader.scss";
import CommonHeading from "../../common/commonHeading/CommonHeading";
import CommonSearch from "../../common/CommonSearch/CommonSearch";
import CommonTable from "../../common/commonTable/CommonTable";
import CustomPagination from "../../common/customPagination/CustomPagination";
import toast from "../../common/toast";
import "./TransactionHistory.scss";

const TransactionHistory = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const admin = useSelector((state: RootState) => state.admin.isAdmin);
  const [token, setToken] = useState<string>("");
  const [transactionData, setTransactionData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState<any>(0);
  const adminThead = [
    { label: "Token" },
    { label: "XToken" },
    { label: "Wallet Address" },
    { label: "Event" },
    { label: "Supply/Withdraw Token" },
    { label: "Minted/Redeem XToken" },
    { label: "Borrow Amount" },
    { label: "Repay Amount" },
    { label: "Txn. Hash" },
    { label: "Date & Time" },
  ];
  const userThead = [
    { label: "Token" },
    { label: "XToken" },
    { label: "Event" },
    { label: "Supply/Withdraw Token" },
    { label: "Minted/Redeem XToken" },
    { label: "Borrow Amount" },
    { label: "Repay Amount" },
    { label: "Txn. Hash" },
    { label: "Date & Time" },
  ];

  const getTransactionHistory = useCallback(
    async (page: number = 1) => {
      try {
        let obj: {
          page: number;
          limit?: number;
          userAddress?: string;
        } = {
          page: page,
          limit: TABLE_LIMIT,
        };
        if (token) {
          obj.userAddress = token;
        }
        if (!admin) {
          obj.userAddress = walletAddress;
        }
        dispatch(loader(true));
        let result: any = await dispatch(
          callApiGetMethod("GET_TRANSACTION_LIST", obj, false)
        );

        if (result) {
          setTransactionData(result?.data?.data);
          setCurrentPage(result?.data?.currentPage);
          setTotalData(result?.data?.totalCount);
          dispatch(loader(false));
        }
      } catch (error) {
        dispatch(loader(false));
        console.log("Error occured in getting borrow market", error);
      }
    },
    [token, walletAddress, admin,  dispatch]
  );

  useEffect(() => {
    getTransactionHistory();
  }, [getTransactionHistory]);
  const onPageChanged = useCallback(
    (page: number) => {
      getTransactionHistory(page);
    },
    [getTransactionHistory]
  );
  const [setCopied] = useCopyClipboard();
  const handleCopy = (data: string, msg: string) => {
    setCopied(data);
    toast.success(`${msg} Copied Successfully`);
  };

  return (
    <>
      <Container fluid={admin}>
        <div className={`lending_currency ${!admin ? "pt-5" : ""}`}>
          <div className="lending_currency_head">
            {admin && <CommonHeading title="Transaction History" />}
            {admin && (
              <CommonSearch
                placeholder="Search"
                label="Search"
                type="text"
                onChange={(e: any) => setToken(e.target.value)}
                name="TokenAddress"
              />
            )}
          </div>
          <div className="table_sec">
            <CommonTable
              tableTitle={"Transactions"}
              fields={admin ? adminThead : userThead}
            >
              {transactionData?.map((item: any) => (
                <tr key={item?.id}>
                  <td>
                    <img src={item?.xTokenInfo?.logo} alt="" />
                    {item?.xTokenInfo?.symbol}
                  </td>
                  {item?.xToken ? (
                    <td>
                      {customizeAddress(item?.xToken)}
                      <button
                        className="copy_btn"
                        onClick={() =>
                          handleCopy(item?.xToken, "Xtoken Adrress")
                        }
                      >
                        <CopyIcon />
                      </button>
                    </td>
                  ) : (
                    "-"
                  )}
                  {admin && (
                    <td>
                      {item?.userAddress ? (
                        <>
                          {customizeAddress(item?.userAddress)}
                          <button
                            className="copy_btn"
                            onClick={() =>
                              handleCopy(item?.userAddress, "Wallet Adrress")
                            }
                          >
                            <CopyIcon />
                          </button>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                  <td>{item?.event}</td>
                  <td>
                    {item?.mintAmount
                      ? `${intToSuffixes(
                          divideBigNumber(
                            item?.mintAmount,
                            String(transactionData[0]?.xTokenInfo?.decimals)
                              .length - 1
                          )
                        )} ${item?.xTokenInfo?.symbol || ""}`
                      : item?.redeemAmount
                      ? `${intToSuffixes(
                          divideBigNumber(
                            item?.redeemAmount,
                            String(transactionData[0]?.xTokenInfo?.decimals)
                              .length - 1
                          )
                        )} ${item?.xTokenInfo?.symbol || ""}`
                      : "-"}
                  </td>
                  <td>
                    {item?.mintTokens
                      ? `${intToSuffixes(
                          divideBigNumber(
                            item.mintTokens,
                            String(transactionData[0]?.xTokenInfo?.decimals)
                              .length - 1
                          )
                        )} ${item?.xTokenInfo?.symbol || ""}`
                      : item?.redeemTokens
                      ? `${intToSuffixes(
                          divideBigNumber(
                            item.redeemTokens,
                            String(transactionData[0]?.xTokenInfo?.decimals)
                              .length - 1
                          )
                        )} ${item?.xTokenInfo?.symbol || ""}`
                      : "-"}
                  </td>
                  <td>
                    {item?.borrowAmount
                      ? `${intToSuffixes(
                          divideBigNumber(
                            item?.borrowAmount,
                            String(transactionData[0]?.xTokenInfo?.decimals)
                              .length - 1
                          )
                        )} ${item?.xTokenInfo?.symbol || ""}`
                      : "-"}
                  </td>
                  <td>
                    {item?.repayAmount
                      ? `${intToSuffixes(
                          divideBigNumber(
                            item.repayAmount,
                            String(transactionData[0]?.xTokenInfo.decimals)
                              .length - 1
                          )
                        )} ${item?.xTokenInfo?.symbol || ""}`
                      : "-"}
                  </td>
                  <td>
                    {item?.transactionHash ? (
                      <a
                        href={item?.transactionHash}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {customizeAddress(
                          item?.transactionHash?.split("/").pop()
                        )}
                        <RedirectIcon />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{moment(item?.createdAt).format(DATE_FORMAT)} </td>
                </tr>
              ))}
            </CommonTable>
            {totalData > TABLE_LIMIT && (
              <div className="pegination_box">
                <CustomPagination
                  activePage={currentPage}
                  itemsCountPerPage={TABLE_LIMIT}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={2}
                  onChange={onPageChanged}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default TransactionHistory;
