// export const API_HOST = "http://10.10.2.73:5050/";
export const API_HOST = "https://stage-api.collatix.com/";
// export const RPC_URL =
//   "https://polygon-amoy.blockpi.network/v1/rpc/public";
// export const CHAIN_ID = 80002;
// export const NETWORK_NAME = "Polygon Amoy";
// export const CURRENCY = "POL";
// export const NETWORK_DECIMALS = 18;
// export const EXPLORER_LINK = "https://amoy.polygonscan.com/";

export const RPC_URL =
  "https://bsc-testnet.blockpi.network/v1/rpc/94ae482e572ad23780d660501f96c70fb3e61f18";
export const CHAIN_ID = 97;
export const NETWORK_NAME = "Bsc Testnet";
export const CURRENCY = "BNB";
export const NETWORK_DECIMALS = 18;
export const EXPLORER_LINK = "https://testnet.bscscan.com/";

export const COMPTROLLER_ADDRESS = "0x5464BcFf3a441aB30eA10aB9533fe2e9F416F7CE";
export const PRICE_ORACLE_ADDRESS =
  "0xfC9f6d58831d7fAd0D78eD0F7dEDa590EAA5905c";
export const FACTORY_ADDRESS = "0x46E323dB6124eB8bBBD23e59274DEad8713Aa599";

export const SECRET_KEY1 = "],k%.XA!]S!bwD2}2NL[]$";
export const SECRET_KEY2 = "2)h@R_Cy}gpy@^{BXnKbPw";
export const SECRET_KEY3 = "u8fDtq4TidnivB5bqCxk5y";
export const SECRET_KEY4 = "ekRkiLnaBaet8mBDrhDk3Ufj";
export const LIMIT = 10;
export const LENGTH = 128;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const PROJECT_ID = "551b3056632250352203d9e6d1178ae3";
