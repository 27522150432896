import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyIcon } from "../../../assets/icons/Icons";
import useCopyClipboard from "../../../hooks/useCopyToClipboard";
import { callApiGetMethod } from "../../../redux/Actions/api.action";
import { loader } from "../../../redux/Slices/loader.slice";
import {
  customizeAddress,
  divideBigNumberWithSuffixes,
  handleBigNumbers,
} from "../../../services/common.service";
import {
  getInterestRateModalDetail,
  getXtokenInfo,
} from "../../../services/contract.service";
import { RootState } from "../../../Types/reduxStateType/RootState";
import { ZERO_ADDRESS } from "../../../utils/Constant";
import { TABLE_LIMIT } from "../../../utils/Utils";
import "../../common/adminHeader/AdminHeader.scss";
import CommonHeading from "../../common/commonHeading/CommonHeading";
import CommonTable from "../../common/commonTable/CommonTable";
import CustomPagination from "../../common/customPagination/CustomPagination";
import AddCurrencyModal from "../../common/modals/addCurrencyModal/AddCurrencyModal";
import BorrowPauseModal from "../../common/modals/borrowPauseModal/BorrowPauseModal";
import InterestRateModal from "../../common/modals/interestRateModal/InterestRateModal";
import MarketListedModal from "../../common/modals/marketListed/MarketListedModal";
import MintPauseModal from "../../common/modals/mintPauseModal/MintPauseModal";
import SetCollateralFactorModal from "../../common/modals/setCollateralFactorModal/SetCollateralFactorModal";
import toast from "../../common/toast";
import "./CurrencyList.scss";
const CurrencyList = () => {
  const thead = [
    { label: "Token" },

    { label: "Underlying Token" },
    { label: "XToken" },

    { label: "Mint Pause" },
    { label: "Borrow Pause" },
    { label: "Market Listed" },
    { label: "Interest Per Block " },
    { label: "Reserve Factor" },
    { label: " Borrow Limit" },
    { label: "Collateral Factor" },
  ];

  const dispatch: Dispatch<any> = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [borrowMarket, setBorrowMarket] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState<any>(0);
  const [setCopied] = useCopyClipboard();

  const getMarketDetails = useCallback(
    async (page: number = 1) => {
      try {
        let obj: {
          page: number;
          limit?: number;
          userAddress?: string;
        } = {
          page: page,
          limit: TABLE_LIMIT,
          userAddress: walletAddress,
        };
        dispatch(loader(true));

        let result: any = await dispatch(
          callApiGetMethod("GET_ALL_TOKENS", obj, false)
        );

        if (result?.success) {
          setBorrowMarket(result?.data?.data);
          let data = await getXtokenInfo(result?.data?.data);
          let data2 = await getInterestRateModalDetail(data);
          setBorrowMarket(data2);
          setCurrentPage(result?.data?.currentPage);
          setTotalData(result?.data?.totalCount);
          dispatch(loader(false));
        }
      } catch (error) {
        dispatch(loader(false));
        console.log("Error occured in getting supply market", error);
      }
    },
    [walletAddress, dispatch]
  );

  useEffect(() => {
    getMarketDetails();
  }, [getMarketDetails]);

  const onPageChanged = useCallback(
    (page: number) => {
      getMarketDetails(page);
    },
    [getMarketDetails]
  );

  const handleCopy = (data: string, msg: string) => {
 
    setCopied(data);
    toast.success(`${msg} Copied Successfully`);
  };

  return (
    <>
      <div className="lending_currency currency_list">
        <div className="lending_currency_head">
          <CommonHeading title={"Add Currencies"} />

          <AddCurrencyModal />
        </div>
        <div className="table_sec">
          <CommonTable tableTitle={"Currency List"} fields={thead}>
            {borrowMarket &&
              borrowMarket?.map((item: any) => (
                <tr key={item?.id}>
                  <td>
                    <img src={item?.logo} alt="" />
                    {item?.symbol}
                  </td>
                  <td>
                    {item?.address !== ZERO_ADDRESS ? (
                      <>
                        {" "}
                        {customizeAddress(item?.address)}
                        <button
                          className="copy_btn"
                          onClick={() => handleCopy(item?.address, "Address")}
                        >
                          <CopyIcon />
                        </button>
                      </>
                    ) : (
                      "-"
                    )}{" "}
                  </td>
                  <td>
                    {customizeAddress(item.xToken)}
                    <button
                      className="copy_btn"
                      onClick={() => handleCopy(item.xToken, "Xtoken Address")}
                    >
                      <CopyIcon />
                    </button>
                  </td>

                  <td>
                    <MintPauseModal data={item} />
                  </td>
                  <td>
                    <BorrowPauseModal data={item} />
                  </td>
                  <td>
                    {" "}
                    {!item?.marketListed ? (
                      <MarketListedModal data={item} />
                    ) : (
                      "Yes"
                    )}
                  </td>
                  <td>
                    {item.baseRatePerBlock && (
                      <div>
                        <span>Base Rate </span>{" "}
                        {divideBigNumberWithSuffixes(
                          handleBigNumbers(
                            item.baseRatePerBlock,
                            item.blocksPerYear,
                            "mul"
                          ),
                          16,
                          true
                        )}
                        {"%"}
                      </div>
                    )}
                    {item.multiplierPerBlock && (
                      <div>
                        <span>Multiplier </span>{" "}
                        {divideBigNumberWithSuffixes(
                          handleBigNumbers(
                            item.multiplierPerBlock,
                            item.blocksPerYear,
                            "mul"
                          ),
                          16,
                          true
                        )}
                        {"%"}
                      </div>
                    )}
                    {item.jumpMultiplierPerBlock && (
                      <div>
                        <span>Jump Multiplier</span>{" "}
                        {divideBigNumberWithSuffixes(
                          handleBigNumbers(
                            item.jumpMultiplierPerBlock,
                            item.blocksPerYear,
                            "mul"
                          ),
                          16,
                          true
                        )}
                        {"%"}
                      </div>
                    )}

                    {item.kink && (
                      <div>
                        <span>Kink </span>{" "}
                        {divideBigNumberWithSuffixes(item.kink, 16, true)}
                        {"%"}
                      </div>
                    )}
                    <InterestRateModal
                      data={item.xToken}
                      title={"Interest Rate Modal"}
                    />
                  </td>

                  <td>
                    {item.markets
                      ? divideBigNumberWithSuffixes(item.markets, 16, true)
                      : 0}
                    <SetCollateralFactorModal
                      data={item}
                      title={"Update Reserve Factor"}
                    />
                  </td>

                  <td>
                    {item.borrowCaps
                      ? divideBigNumberWithSuffixes(
                          item.borrowCaps,
                          String(item?.decimals).length - 1 || 1,
                          true
                        )
                      : 0}
                    <SetCollateralFactorModal
                      data={item}
                      title={"Update Borrow Limit"}
                    />
                  </td>

                  <td>
                    {divideBigNumberWithSuffixes(
                      item.collateralFactorMantissa,
                      16,
                      true
                    )}
                    <SetCollateralFactorModal
                      data={item}
                      title={"Update Collateral Factor"}
                    />
                  </td>
                </tr>
              ))}
          </CommonTable>
          {totalData > TABLE_LIMIT && (
            <div className="pegination_box">
              <CustomPagination
                activePage={currentPage}
                itemsCountPerPage={TABLE_LIMIT}
                totalItemsCount={totalData}
                pageRangeDisplayed={2}
                onChange={onPageChanged}
              />
            </div>
           )} 
        </div>
      </div>
    </>
  );
};

export default CurrencyList;
