import BigValue from "bignumber.js";
import CryptoJS from "crypto-js";
import JSBI from "jsbi";
// import Big from 'big.js';
import {
  LENGTH,
  SECRET_KEY1,
  SECRET_KEY2,
  SECRET_KEY3,
  SECRET_KEY4,
} from "../utils/Constant";
import { ErrorInfo, FailureInfo } from "../utils/Errors";

/**CUTMIZE ADDRESS FOR SHOW */
export const customizeAddress = (address: string) => {
  const firstFive = address?.substring(0, 5);
  const lastFour = address.substring(address.length - 4);
  return firstFive + "..." + lastFour;
};
export const multiplyTwoBigDigits = (valueOne: any, valueTwo: any) => {
  const a = JSBI.BigInt(valueOne);
  const b = JSBI.BigInt(valueTwo);
  const result = JSBI.multiply(a, b);
  return String(result);
};

// export const multiplyBigNumbers = (num1: string, num2: string) => {
//   const bigDecimal1 = new Big(num1);
//   const bigDecimal2 = new Big(num2);

//   const result = bigDecimal1.times(bigDecimal2);

//   return result.toString(); // Convert the result back to a string for display
// };

export const multiplyBigDigitsWithDecimals = (
  valueOne: string,
  valueTwo: string
) => {
  let a: any;
  let b: any;
  let decimalLengthA: any = 0;
  let decimalLengthB: any = 0;

  if (valueOne.includes(".")) {
    a =
      valueOne.split(".")[1].length > 0
        ? convertWithDecimal(valueOne, valueOne.split(".")[1].length)
        : valueOne.split(".")[0];
    decimalLengthA = valueOne.split(".")[1].length;
  } else {
    a = valueOne;
  }
  if (valueTwo.includes(".")) {
    b = convertWithDecimal(valueTwo, valueTwo.split(".")[1].length);
    decimalLengthB = valueTwo.split(".")[1].length;
  } else {
    b = valueTwo;
  }
  const decimalLength = decimalLengthA + decimalLengthB;
  let result = multiplyTwoBigDigits(a, b);
  if (
    result.substring(0, result.length - decimalLength).length &&
    result.substring(result.length - decimalLength).length
  ) {
    result =
      result.substring(0, result.length - decimalLength) +
      "." +
      result.substring(result.length - decimalLength);
  } else if (!result.substring(0, result.length - decimalLength).length) {
    // eslint-disable-next-line
    result = "0" + "." + result.substring(result.length - decimalLength);
  }
  return result;
};

function numberToString(arg: any) {
  if (typeof arg === "string") {
    if (!arg.match(/^-?[0-9.]+$/)) {
      throw new Error(
        "while converting number to string, invalid number value '" +
          arg +
          "', should be a number matching (^-?[0-9.]+)."
      );
    }
    return arg;
  } else if (typeof arg === "number") {
    return String(arg);
  } else if (
    typeof arg === "object" &&
    arg.toString &&
    (arg.toTwos || arg.dividedToIntegerBy)
  ) {
    if (arg.toPrecision) {
      return String(arg.toPrecision());
    } else {
      // eslint-disable-line
      return arg.toString(10);
    }
  }
  throw new Error(
    "while converting number to string, invalid number value '" +
      arg +
      "' type " +
      typeof arg +
      "."
  );
}

// Function to convert into wei
function toWei(input: any, unit: any) {
  var ether = numberToString(input); // eslint-disable-line
  const base = unit;
  const baseLength = base.length - 1 || 1;
  if (ether === ".") {
    throw new Error(
      "[ethjs-unit] while converting number " + input + " to wei, invalid value"
    );
  }

  // Is it negative?
  const negative = ether.substring(0, 1) === "-";

  if (negative) {
    ether = ether.substring(1);
  }
  // Split it into a whole and fractional part
  var comps = ether.split("."); // eslint-disable-line
  if (comps.length > 2) {
    throw new Error(
      "[ethjs-unit] while converting number " +
        input +
        " to wei,  too many decimal points"
    );
  }
  let whole = comps[0],
    fraction = comps[1]; // eslint-disable-line
  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > baseLength) {
    throw new Error(
      "[ethjs-unit] while converting number " +
        input +
        " to wei, too many decimal places"
    );
  }

  while (fraction.length < baseLength) {
    fraction += "0";
  }

  if (!parseInt(whole)) {
    return fraction.replace(/^0*(?=[1-9])/g, "");
  }

  if (negative) {
    return "-" + whole + fraction;
  }

  return whole + fraction;
}

function fromWei(value: any, numberOfDecimals: any) {
  const numberOfZerosInDenomination = numberOfDecimals.length - 1;
  if (numberOfZerosInDenomination <= 0) return value;
  const zeroPaddedValue = value?.padStart(numberOfZerosInDenomination, "0");
  const integer = zeroPaddedValue?.slice(0, -numberOfZerosInDenomination);
  const fraction = zeroPaddedValue
    ?.slice(-numberOfZerosInDenomination)
    .replace(/\.?0+$/, "");
  if (integer === "") return `0.${fraction}`;
  if (fraction === "") return integer;
  return `${integer}.${fraction}`;
}

export const intToSuffixes = (num: any, fixed = 2) => {
  num = parseFloat(num);
  // console.log("num", num);
  if (isNaN(num)) {
    return null; // terminate early for invalid numbers
  }
  if (num === 0) {
    return "0"; // terminate early for zero
  }

  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const suffixes = ["", "K", "M", "B", "T", "P", "E"]; // suffixes for positive powers
  const smallSuffixes = ["", "m", "µ", "n", "p", "f"]; // suffixes for negative powers

  const b: any = num.toPrecision(2).split("e"), // get power notation
    exponent = b.length === 1 ? 0 : parseInt(b[1]), // extract exponent
    k = exponent >= 0 ? Math.floor(exponent / 3) : Math.ceil(exponent / 3), // floor for positive, ceil for negative
    power = Math.pow(10, k * 3);
  // console.log("b", b, num);
  // Check if the negative exponent exceeds 5
  if (exponent < 0 && Math.abs(exponent) > 5) {
    return "<0.00001"; // return 0 if negative exponent exceeds 5
  }

  let c = (num / power).toFixed(fixed); // Limit to 'fixed' decimal places

  // Remove unnecessary trailing zeroes after the decimal point
  c = parseFloat(c).toString();
  if (k >= 0) {
    // Handle large numbers
    return c + (suffixes[k] || ""); // append appropriate suffix
  } else {
    // Handle small numbers (negative powers)
    return c + (smallSuffixes[-k] || ""); // append small suffix
  }
};

export const divideBigNumberWithSuffixes = (
  value: any,
  decimal: number,
  suffixes = false
) => {
  if (!decimal || decimal === 0 || !value) {
    return "0";
  } else {
    const decimalBigN = JSBI.BigInt(decimal);
    const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
    const x = new BigValue(value?.toString());
    const y = new BigValue(String(convertedDecimal));
    const z = x.dividedBy(y);
    return suffixes
      ? intToSuffixes(parseFloat(z.toString()))
      : fixedToDecimal(z.toString(), 5);
  }
};

// eslint-disable-next-line import/no-unused-modules
export const divideBigNumber = (value: any, decimal: number) => {
  if (!decimal || decimal === 0 || !value) {
    return "0";
  } else {
    const decimalBigN = JSBI.BigInt(decimal);
    const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
    const x = new BigValue(value?.toString());
    const y = new BigValue(String(convertedDecimal));
    const z = x.dividedBy(y);
    return fixedToDecimal(z.toString(), decimal);
  }
};

/** Divide with Decimal*/
export const divideWithDecimal = (value: any, decimal: any) => {
  const decimalBigN = JSBI.BigInt(decimal);
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  let result = fromWei(value, String(convertedDecimal));
  // result = parseFloat(result).toLocaleString('en-US')
  return result;
};

/**CONVERT NUMBER WITH DECIMALS FOR CONTRACT CALL */
export const convertWithDecimal = (value: any, decimal: any) => {
  const decimalBigN = JSBI.BigInt(decimal);
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  return toWei(value, String(convertedDecimal));
};

/**REMOVE e FORM BIG NUMBER */
// eslint-disable-next-line import/no-unused-modules
export const toFixed = (x: any) => {
  let e: any;
  if (Math.abs(x) < 1.0) {
    // eslint-disable-next-line no-var
    e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

/**GET ERROR MESSAGE FORM ERROR OBJECT */
export const getError = (error: any) => {
  const errorMsg = error.message ? error.message : error;
  if (errorMsg.indexOf("execution reverted") > -1) {
    let msg = errorMsg;
    msg = msg =
      msg.indexOf("execution reverted:") > -1
        ? msg.split("execution reverted:")[1].split("{")[0].split('"')[0]
        : msg;
    return msg;
  } else if (errorMsg.indexOf("INVALID_ARGUMENT") > -1) {
    return errorMsg.split("(")[0];
  } else if (errorMsg.indexOf("Returned error") > -1) {
    return errorMsg.split(":")[1];
  } else if (errorMsg.indexOf("MetaMask Tx Signature") > -1) {
    const msg = errorMsg.replace("MetaMask Tx Signature:", "");
    return msg;
  } else if (errorMsg.indexOf("Internal JSON-RPC error") > -1) {
    const msg = errorMsg.replace("MetaMask Tx Signature:", "");
    return msg;
  } else if (
    errorMsg.indexOf("Transaction has been reverted by the EVM:") > -1
  ) {
    const msg = errorMsg.split(":")[0];
    return msg;
  } else {
    const err = errorMsg.split("*")[0].split(":")[1];
    if (err?.trim() === "Insufficient funds for gas") {
      return err;
    } else {
      return errorMsg;
    }
  }
};

export function extractErrorMessage(error: any) {
  console.log("error--->>>>", error);
  if (error?.cause?.data?.message) {
    console.log("1", error.cause);
    return new Error(error.cause.data.message);
  } else if (error?.cause?.message) {
    console.log("2", error.cause);
    return new Error(error.cause.message);
  } else if (error?.reason) {
    console.log("3", error?.reason);
    return new Error(error?.reason);
  } else if (error.data?.message) {
    console.log("4", error.data);
    return new Error(error.data.message);
  } else {
    return error;
  }
}

/**CREATE URL FOR API CALL WITH PARAMS */
export const formatUrl = (url: string, params: any) => {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ``;
  return `${url}${params}`;
};

/**ALLOW ONLY STRING */
export const allowOnlyString = (inputString: any) => {
  const res = /^[a-zA-Z]+$/.test(inputString);
  return res;
};

/**SHOW VALUE WITH ONLY SELECTED DECIMALS */
// eslint-disable-next-line import/no-unused-modules
export const fixedToDecimal = (value: any, decimals: any = 5) => {
  if (value && parseFloat(value) !== 0) {
    // Convert value to a number and then back to a string to handle scientific notation
    value = parseFloat(value).toFixed(decimals);
    // Create the regex dynamically based on the decimals argument
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`);

    // Match the value using the dynamic regex
    const match = value.toString().match(regex);

    // Return the matched value or default to 0 if no match
    value = match ? match[0] : "0";
  } else {
    value = "0";
  }
  return value;
};

export const allowOnlyNumberWithDecimalsInput = (value: any, decimals: any) => {
  let re;
  let decimalValue = Number(decimals);

  re = new RegExp(
    "^(\\d{0,10}|\\d{0,12}\\.\\d{0," + decimalValue + "})$",
    "gm"
  );
  if (re?.test(value)) {
    return true;
  } else {
    return false;
  }
};

function isPrime(number: number) {
  if (number <= 1) {
    return false;
  }
  if (number <= 3) {
    return true;
  }
  if (number % 2 === 0 || number % 3 === 0) {
    return false;
  }
  for (let i = 5; i * i <= number; i += 6) {
    if (number % i === 0 || number % (i + 2) === 0) {
      return false;
    }
  }
  return true;
}

function getPrimeNumbersInRange(start: number, end: number) {
  const primeNumbers: any = [];

  for (let number = start; number <= end; number++) {
    if (isPrime(number)) {
      primeNumbers.push(number);
    }
  }
  return primeNumbers;
}
function getKey(value: number) {
  let encyptionKey = SECRET_KEY1.concat(SECRET_KEY2, SECRET_KEY3, SECRET_KEY4);

  const primeNumbers: any = getPrimeNumbersInRange(1, value);
  const string = primeNumbers
    .map((number: number) => encyptionKey[number])
    .join("");
  return string;
}

export const decrypt = (data: any, string = false) => {
  try {
    const key: string = getKey(Number(LENGTH));
    const decryptData = CryptoJS.AES.decrypt(data, key);
    let stringData = decryptData.toString(CryptoJS.enc.Utf8);
    stringData = string ? stringData : JSON.parse(stringData);
    return stringData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return false;
  }
};

export const encryptData = (data: any) => {
  const key: string = getKey(Number(LENGTH));
  const stringData = JSON.stringify(data);
  const encryptData = CryptoJS.AES.encrypt(stringData, key).toString();
  return encryptData;
};

export const addBigNumber = (num1: any, num2: any) => {
  num1 = Math.trunc(num1);
  num2 = Math.trunc(num2);
  num1 = JSBI.BigInt(num1);
  num2 = JSBI.BigInt(num2);
  return JSBI.add(num1, num2).toString();
};

export const handleBigNumbers = (
  number1: any,
  number2: any,
  action: string = "sum"
) => {
  try {
  
    const bigInt1 = BigInt(
      number1.toString().includes(".")
        ? number1.toString().split(".")[0]
        : number1
    );
    const bigInt2 = BigInt(
      number2.toString().includes(".")
        ? number2.toString().split(".")[0]
        : number2
    );
      let result;
    switch (action) {
      case "sum":
        result = bigInt1 + bigInt2;
        break;
      case "sub":
        result = bigInt1 - bigInt2;
        break;
      case "mul":
        result = bigInt1 * bigInt2;
        break;
      case "div":
        result = bigInt1 / bigInt2;
        break;
      default:
        result = 0;
    }
    result = result.toString().includes(".")
      ? result.toString().split(".")[0]
      : result;
   
    return result.toString().replace(/n$/, "");
  } catch (error) {
    console.log("Error", error);
  }
};

export const numberToOrdinal = (num: number) => {
  if (num === 0) {
    return "0";
  }

  const suffixes = ["th", "st", "nd", "rd"];
  const lastDigit = num % 10;
  const suffix =
    suffixes[lastDigit <= 3 && (num % 100) - lastDigit !== 10 ? lastDigit : 0];
  return num + suffix;
};

export const processNumericInput = (input: any) => {
  const stringValue = input.toString();
  const decimalIndex = stringValue.indexOf(".");

  if (decimalIndex !== -1) {
    const slicedValue = stringValue.slice(0, decimalIndex + 6);
    return slicedValue;
  } else {
    return input;
  }
};

export const getFailureErrorMsg = (value: number) => {
  switch (value) {
    case FailureInfo.ACCEPT_ADMIN_PENDING_ADMIN_CHECK:
      return "";
    case FailureInfo.ACCEPT_PENDING_IMPLEMENTATION_ADDRESS_CHECK:
      return "";
    case FailureInfo.EXIT_MARKET_BALANCE_OWED:
      return "You're having borrow balance for the same token.";
    case FailureInfo.EXIT_MARKET_REJECTION:
      return "You cannot disable collateral at this moment.";
    case FailureInfo.SET_CLOSE_FACTOR_OWNER_CHECK:
      return "";
    case FailureInfo.SET_CLOSE_FACTOR_VALIDATION:
      return "";
    case FailureInfo.SET_COLLATERAL_FACTOR_VALIDATION:
      return "Your collateral factor percentage should not be greater than 90%.";

    case FailureInfo.SET_COLLATERAL_FACTOR_NO_EXISTS:
      return "You're not able to proceed because market is not listed..";
    case FailureInfo.SET_COLLATERAL_FACTOR_OWNER_CHECK:
      return "Unauthorized Action.";

    case FailureInfo.SET_COLLATERAL_FACTOR_WITHOUT_PRICE:
      return "Your collateral factor/token price should be greater than 0.";
    case FailureInfo.SET_IMPLEMENTATION_OWNER_CHECK:
      return "";
    case FailureInfo.SET_LIQUIDATION_INCENTIVE_OWNER_CHECK:
      return "Unauthorized Action.";
    case FailureInfo.SET_LIQUIDATION_INCENTIVE_VALIDATION:
      return "";
    case FailureInfo.SET_MAX_ASSETS_OWNER_CHECK:
      return "";
    case FailureInfo.SET_PENDING_ADMIN_OWNER_CHECK:
      return "";
    case FailureInfo.SET_PENDING_IMPLEMENTATION_OWNER_CHECK:
      return "";
    case FailureInfo.SET_PRICE_ORACLE_OWNER_CHECK:
      return "Unauthorized Action.";
    case FailureInfo.SUPPORT_MARKET_EXISTS:
      return "You're not able to proceed because market is not listed.";
    case FailureInfo.SUPPORT_MARKET_OWNER_CHECK:
      return "";
    case FailureInfo.SET_PAUSE_GUARDIAN_OWNER_CHECK:
      return "Unauthorized Action.";
    default:
      return "Unknown Error Occured";
  }
};

export const getCustomErrorMsg = (errorMsg: string, errorCode: number = 0) => {
  switch (errorMsg) {
    case "TransferComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        case ErrorInfo.INSUFFICIENT_LIQUIDITY:
          return "Your account don't have sufficient liquidity.";
        default:
          return "Transfer Rejected.";
      }
    case "TransferNotAllowed":
      return "Transfer Not Allowed.";
    case "TransferNotEnough":
      return "Insufficient Balance.";
    case "TransferTooMuch":
      return "Limit exhausted.";
    case "MintComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        default:
          return "We couldn't complete your transaction. Please verify your details and try again.";
      }
    // Minting error:
    // 1.Transaction failed due to a mint freshness check.
    // 2. Unable to mint tokens due to an outdated state.
    case "MintFreshnessCheck":
      return "Supply failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";
    case "RedeemComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        case ErrorInfo.INSUFFICIENT_LIQUIDITY:
          return "Your account don't have sufficient liquidity.";
        default:
          return "We couldn't complete your transaction. Please verify your details and try again.";
      }
    case "RedeemFreshnessCheck":
      return "Withdraw failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";
    case "RedeemTransferOutNotPossible":
      return "Withdraw failed. It looks like we're unable to transfer your assets right now. Please check back soon.";
    case "BorrowComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        case ErrorInfo.INSUFFICIENT_LIQUIDITY:
          return "Borrow limit exceeds. Please check back later.";
        default:
          return "We couldn't complete your transaction. Please verify your details and try again.";
      }
    case "BorrowFreshnessCheck":
      return "Borrow failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";
    case "BorrowCashNotAvailable":
      return "It looks like there aren't enough funds to borrow right now. Please check back later.";
    case "RepayBorrowComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        default:
          return "Transfer Rejected.";
      }
    case "RepayBorrowFreshnessCheck":
      return "Repay failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";
    case "LiquidateComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        case ErrorInfo.INSUFFICIENT_SHORTFALL:
          return "Liquidation failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";
        case ErrorInfo.TOO_MUCH_REPAY:
          return "Repay amount is greater than liquidation limit. Please verify your details and try again.";

        default:
          return "We couldn't complete your transaction. Please verify your details and try again.";
      }

    case "LiquidateFreshnessCheck":
      return "Liqudation failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";

    case "LiquidateCollateralFreshnessCheck":
      return "Liqudation failed because the transaction is no longer valid. Please refresh your session and attempt the transaction again.";

    case "LiquidateLiquidatorIsBorrower":
      return "You can't liquidate your own tokens.";

    case "LiquidateSeizeLiquidatorIsBorrower":
      return "You can't liquidate your own tokens.";

    case "LiquidateCloseAmountIsZero":
      return "Please enter a valid amount.";

    case "LiquidateCloseAmountIsUintMax":
      return "Please enter a valid amount.";

    case "LiquidateSeizeComptrollerRejection":
      switch (errorCode) {
        case ErrorInfo.MARKET_NOT_LISTED:
          return "You're not able to proceed because market is not listed.";
        case ErrorInfo.COMPTROLLER_MISMATCH:
          return "You're not able to proceed because market is not listed.";

        default:
          return "We couldn't complete your transaction. Please verify your details and try again.";
      }

    default:
      return "Unknown Error Occured";
  }
};
