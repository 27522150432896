import { useFormik } from "formik";
import { Dispatch, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { RedirectIcon } from "../../../../assets/icons/Icons";
import { callApiGetMethod } from "../../../../redux/Actions/api.action";
import {
  callContractSendMethod,
  validateAddress,
} from "../../../../redux/Actions/contract.action";
import {
  convertWithDecimal,
  getFailureErrorMsg,
} from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import { Yup } from "../../../../utils/Utils";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import CustomSelect from "../../customSelect/CustomSelect";
import { default as toast, default as toaster } from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./AddCurrencyModal.scss";

const AddCurrencyModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tokenData, setTokenData] = useState<any>("");
  const { connector } = useAccount();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const [selectVal, setSelectVal] = useState<string>("");
  const formik = useFormik({
    initialValues: {
      address: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const isValidAddress: any = await dispatch(
        validateAddress(values.address, "contract")
      );
      // console.log(isValidAddress,"isValidAddress............")
      if (isValidAddress) {
        getTokenInformation(values.address);
      } else {
        setLoading(false);
        toast.error("Please enter a valid token address");
      }
      // getTokenInformation(values.address);
    },
  });

  const jrmSchema = Yup.object().shape({
    initialExchangeRateMantissa: Yup.number()
      .moreThan(
        0,
        "Initial Exchange Rate Mantissa must be greater than or equal to 0"
      )
      .required("Initial Exchange Rate Mantissa is required"),
    baseRatePerYear: Yup.number()
      .moreThan(0, "Base Rate Per Year must be greater than or equal to 0")
      .max(10, "Value must not be greater than 10")
      .required("Base Rate Per Year is required"),
    multiplierPerYear: Yup.number()
      .moreThan(0, "Multiplier Per Year must be greater than or equal to 0")
      .max(100, "Value must not be greater than 100 %")
      .required("Multiplier Per Year is required"),
    jumpMultiplierPerYear: Yup.number()
      .moreThan(
        0,
        "Jump Multiplier Per Year must be greater than or equal to 0"
      )
      .max(100, "Value must not be greater than 100 %")

      .required("Jump Multiplier Per Year is required"),
    kink: Yup.number()
      .moreThan(0, "Kink value must be greater than or equal to 0")
      .max(100, "Value must not be greater than 100 %")

      .required("Kink value is required"),
    interestRateModel: Yup.string().required("Interest Rate Model is required"),
  });

  const wrmSchema = Yup.object().shape({
    initialExchangeRateMantissa: Yup.number()
      .moreThan(
        0,
        "Initial Exchange Rate Mantissa must be greater than or equal to 0"
      )
      .required("Initial Exchange Rate Mantissa is required"),
    baseRatePerYear: Yup.number()
      .moreThan(0, "Base Rate Per Year must be greater than or equal to 0")
      .max(10, "Value must not be greater than 10")
      .required("Base Rate Per Year is required"),
    multiplierPerYear: Yup.number()
      .moreThan(0, "Multiplier Per Year must be greater than or equal to 0")
      .max(100, "Value must not be greater than 100 %")
      .required("Multiplier Per Year is required"),
    jumpMultiplierPerYear: Yup.number()
      .min(0, "Jump Multiplier Per Year must be greater than or equal to 0")
      .max(100, "Value must not be greater than 100 %")
      .required("Jump Multiplier Per Year is required"),
    kink: Yup.number()
      .min(0, "Kink value must be greater than or equal to 0")
      .max(100, "Value must not be greater than 100 %")
      .required("Kink value is required"),
  });

  const formik2 = useFormik({
    initialValues: {
      initialExchangeRateMantissa: 0,
      baseRatePerYear: 0,
      multiplierPerYear: 0,
      jumpMultiplierPerYear: 0,
      kink: 0,
      interestRateModel: "",
    },
    validationSchema: selectVal === "WRM" ? wrmSchema : jrmSchema,
    onSubmit: async (values) => {
      createNewPairs(values);
    },
  });
  const getTokenInformation = async (_address: string) => {
    try {
      let obj: {
        tokenAddress: string;
      } = {
        tokenAddress: _address,
      };
// console.log('obj', obj)
      let result: any = await dispatch(
        callApiGetMethod("GET_TOKEN_INFO", obj, false)
      );
      if (result?.success) {
        setTokenData(result?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error occured in getting token information", error);
    }
  };

  const createNewPairs = async (values: any) => {
    let randomByteVal = numberTo32Bytes();
    let provider = await connector?.getProvider();
    
    if (tokenData) {
      setShowConfirmation(true);
    setModalKey("createPair");
    handleClose();
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "createNewPair",
          [
            convertWithDecimal(
              1 / values.initialExchangeRateMantissa,
              tokenData?.decimals
            ),
            convertWithDecimal(values.baseRatePerYear, 16),
            convertWithDecimal(values.multiplierPerYear, 16),
            convertWithDecimal(values.jumpMultiplierPerYear, 16),
            convertWithDecimal(values.kink, 16),
            tokenData?.decimals,
            tokenData?.tokenAddress,
            tokenData?.name,
            tokenData?.symbol,
            randomByteVal,
          ],
          walletAddress,
          "factory",
          "createPair"
        )
      );

      if (result?.status) {
        if (result?.events?.Paircreated) {
          supportMarket(result?.events?.Paircreated?.returnValues.xToken);
        }
      } else if (result === undefined) {
        setModalKey("error");
      }
    } else {
      toast.error("Some error in Function calling");
    }
  };

  const supportMarket = async (newPairAddress: string) => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("createPair");
    handleClose()// check here
    
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_supportMarket",
          [newPairAddress],
          walletAddress,
          "comptroller",
          "createPair"
        )
      );
      if (result?.status) {
        if (result?.events?.Failure) {
          let errorMSg = getFailureErrorMsg(
            Number(result?.events?.Failure?.returnValues?.info)
          );
          toaster.error(errorMSg);
          setModalKey("error");
        }else{
          toast.success("Transaction successfull");
        }
      } else if (result === undefined) {
        setModalKey("error");
      }

  };
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setSelectVal("");
    setShow(false);
    formik.resetForm();
    formik2.resetForm();
    setTokenData("");
    setLoading(false);
  };

  function numberTo32Bytes(): string {
    let hex = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
    let paddedHex = hex.padEnd(64, "0");
    return "0x" + paddedHex;
  }
  const optionsArray = [
    {
      label: "Jumprate Module",
      value: "JRM",
    },
    {
      label: "Whitepaper Module",
      value: "WRM",
    },
  ];

  return (
    <>
      <Button onClick={handleShow}>Add Currencies</Button>
      <CommonModal
        show={show}
        onHide={handleClose}
        title={"Add Currencies"}
        className="add_currency_modal"
      >
        <form className="address_form" onSubmit={formik.handleSubmit}>
          <>
            <InputCustom
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              placeholder={"Enter Token Address"}
              label={"Address"}
              name="address"
              maxLength={45}

            />

            {!tokenData && (
              <Button className="address_btn" type="submit">
                Fetch Data
              </Button>
            )}
          </>
        </form>

        {loading && (
          <div className="spinner_box">
            <Spinner />
          </div>
        )}
        {tokenData && (
          <>
            <ul>
              <li>
                <img src={tokenData?.logo} alt="" />
                {tokenData?.symbol}
              </li>
              <li>{tokenData?.name}</li>
              <li>
                <a
                  href={tokenData?.urls?.explorer[2]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <RedirectIcon />
                </a>
              </li>
            </ul>

            <form className="address_form" onSubmit={formik2.handleSubmit}>
              <>
                <InputCustom
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  value={
                    formik2.values.initialExchangeRateMantissa
                      ? formik2.values.initialExchangeRateMantissa
                      : ""
                  }
                  placeholder={"Enter Token conversion rate"}
                  label={"Token conversion rate"}
                  name="initialExchangeRateMantissa"
                  type="number"
                  maxLength={10}
                  error={
                    formik2.touched.initialExchangeRateMantissa &&
                    formik2.errors.initialExchangeRateMantissa
                  }
                />
                <CustomSelect
                  label=" Select Interest rate"
                  onChange={(option: any) => {
                    formik2.values.interestRateModel = option.value;
                    setSelectVal(option.value);
                  }}
                  onBlur={formik2.handleBlur}
                  value={formik2.values.interestRateModel}
                  name="interestRateModel"
                  options={optionsArray}
                  error={
                    formik2.touched.interestRateModel &&
                    formik2.errors.interestRateModel
                  }
                />
                <InputCustom
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  value={
                    formik2.values.baseRatePerYear
                      ? formik2.values.baseRatePerYear
                      : ""
                  }
                  placeholder={"Enter Base Rate"}
                  label={"Base Rate Per Year"}
                  name="baseRatePerYear"
                  type="number"
                  maxLength={2}
                  error={
                    formik2.touched.baseRatePerYear &&
                    formik2.errors.baseRatePerYear
                  }
                />
                <InputCustom
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  value={
                    formik2.values.multiplierPerYear
                      ? formik2.values.multiplierPerYear
                      : ""
                  }
                  placeholder={"Multiplier Per Year"}
                  label={"Multiplier Per Year"}
                  name="multiplierPerYear"
                  type="number"
                  maxLength={3}

                  error={
                    formik2.touched.multiplierPerYear &&
                    formik2.errors.multiplierPerYear
                  }
                />
                {formik2.values.interestRateModel == "JRM" && (
                  <>
                    <InputCustom
                      onChange={formik2.handleChange}
                      onBlur={formik2.handleBlur}
                      onFocus={() => {
                        if (formik2.values.jumpMultiplierPerYear === 0) {
                          formik2.setFieldValue('jumpMultiplierPerYear', '');
                        }
                      }}
                      value={formik2.values.jumpMultiplierPerYear}

                      placeholder={"Jump Multiplier Per Year"}
                      label={"Jump Multiplier Per Year"}
                      name="jumpMultiplierPerYear"
                      type="number"
                      maxLength={3}
                      error={
                        formik2.touched.jumpMultiplierPerYear &&
                        formik2.errors.jumpMultiplierPerYear
                      }
                    />
                    <InputCustom
                      onChange={formik2.handleChange}
                      onBlur={formik2.handleBlur}
                      onFocus={() => {
                        if (formik2.values.kink === 0) {
                          formik2.setFieldValue('kink', '');
                        }
                      }}
                      value={formik2.values.kink}
                      placeholder={"kink"}
                      label={"Kink"}
                      name="kink"
                      type="number"
                      maxLength={3}
                      error={formik2.touched.kink && formik2.errors.kink}
                    />
                  </>
                )}
                <InputCustom
                  value={"x" + tokenData?.name}
                  placeholder={"Enter Name"}
                  label={"Name"}
                  name="_name"
                  disabled
                />
                <InputCustom
                  value={"x" + tokenData?.symbol}
                  placeholder={"Enter Symbol"}
                  label={"Symbol"}
                  name="_symbol"
                  disabled
                />
                <Button className="address_btn" type="submit">
                  Submit
                </Button>
              </>
            </form>
          </>
        )}
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default AddCurrencyModal;
