import { Spinner, Table } from "react-bootstrap";
import "./CommonTable.scss";
import NoRecord from "../../../assets/images/no-record.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../Types/reduxStateType/RootState";

type field = {
  label?: string;
};

type TProps = {
  children?: any;
  fields?: field[];
  tableTitle?: any;
};

const CommonTable = (props: TProps) => {
  const loadingStates = useSelector(
    (state: RootState) => state.loader.isLoading
  );
  
  return (
    <>
      <div className="table_box">
        {props.tableTitle && <h3>{props.tableTitle}</h3>}
        <Table responsive>
          <thead>
            <tr>
              {props.fields?.map((item) => {
                return <th key={item.label}>{item.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {props?.children?.length > 0 ? (
              props?.children
            ):  loadingStates?  <tr>
            <td colSpan={10}>
              <div className="spinner_box">
                <Spinner />
              </div>
            </td>
          </tr> : (
              <tr>
                <td
                  className="text-center noRecord"
                  colSpan={props?.fields ? props?.fields?.length : 0}
                >
                  <img src={NoRecord} alt="No Record" />
                  <p> No Record Found</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default CommonTable;
