import { useWeb3Modal } from "@web3modal/wagmi/react";
import Button from "../button/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../Types/reduxStateType/RootState";
import { customizeAddress } from "../../../services/common.service";

const ConnectWallet = () => {
  const { open } = useWeb3Modal();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );

  return (
    <Button className="light_bg_btn"  onClick={()=>open()}>
       {walletAddress ? customizeAddress(walletAddress) : "Connect Wallet"}
    </Button>
  );
};

export default ConnectWallet;
