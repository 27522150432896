import { Dispatch, useCallback, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { BlueRightIcon } from "../../../../assets/icons/Icons";
import useDebounce from "../../../../hooks/useDebounce";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../redux/Actions/contract.action";
import {
  allowOnlyNumberWithDecimalsInput,
  convertWithDecimal,
  divideBigNumber,
  divideBigNumberWithSuffixes,
  intToSuffixes,
} from "../../../../services/common.service";
import { modalPropTypes } from "../../../../Types/componentPropTypes/modalPropType";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import { ZERO_ADDRESS } from "../../../../utils/Constant";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import Shimmer from "../../shimmer/Shimmer";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./BorrowRepayModal.scss";

const BorrowRepayModal = ({
  item,
  borrowRemaining,
  borrowUsed,
  borrowLimitUsed,
  borrowBalance,
  callback,
}: modalPropTypes) => {
  const dispatch: Dispatch<any> = useDispatch();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const usdDecimals = useSelector(
    (state: RootState) => state.token.usdDecimals
  );
  const [tabsData, setTabsData] = useState(true);
  const [show, setShow] = useState(false);
  const onHide = () => {
    setShow(false);
    setAmount("");
  };
  const [amount, setAmount] = useState<any>();
  const [amountInUsd, setAmountInUsd] = useState<any>(0);
  const [borrowLimitForToken, setBorrowLimitForToken] = useState<any>(0);
  const { connector } = useAccount();
  const [modalKey, setModalKey] = useState<any>("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleAmountchange = (e: any) => {
    let value = e.target.value;
    if (value.startsWith(".")) {
      value = `0${value}`;
    }
    let valid = allowOnlyNumberWithDecimalsInput(
      value,
      String(item?.decimals).length - 1 || 1
    );
    valid && setAmount(value);
  };

  const handleBorrow = async () => {
    setModalKey("borrow");
    setShowConfirmation(true);
    onHide();
    const provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "borrow",
        [convertWithDecimal(amount, String(item?.decimals).length - 1 || 1)],
        walletAddress,
        item?.address === ZERO_ADDRESS ? "cether" : "cerc20",
        "borrow",
        "",
        item?.xToken
      )
    );
    if (result?.status) {
      setTimeout(() => callback && callback(), 5000);
      onHide();
      // toast.success(`${amount} ${item?.symbol} borrowed successfully`);
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const handleRepay = async () => {
    setModalKey("borrow");
    setShowConfirmation(true);
    onHide();
    const provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "repayBorrow",
        [convertWithDecimal(amount, String(item?.decimals).length - 1 || 1)],
        walletAddress,
        item?.address === ZERO_ADDRESS ? "cether" : "cerc20",
        "borrow",
        item?.address === ZERO_ADDRESS
          ? convertWithDecimal(amount, String(item?.decimals).length - 1 || 1)
          : "",
        item?.xToken,
        item?.address,
        item?.address === ZERO_ADDRESS ? true : false
      )
    );

    if (result?.status) {
      setTimeout(() => callback && callback(), 5000);
      onHide();
      // toast.success(`Repay successful`);
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const calculateAmountInUsd = async () => {
    const price: any = await dispatch(
      callContractGetMethod("getUnderlyingPrice", [item?.xToken], "priceOracle")
    );
    let balanceInUsd = divideBigNumber(
      Number(amount) * Number(price),
      usdDecimals
    );
    setAmountInUsd(balanceInUsd);
  };

  const calculateBorrowLimitForToken = useCallback(async () => {
    const price: any = await dispatch(
      callContractGetMethod("getUnderlyingPrice", [item?.xToken], "priceOracle")
    );
    let limit = borrowRemaining;
    let borrowLimitForToken =
      Number(limit) / divideBigNumber(price, usdDecimals);
    setBorrowLimitForToken(borrowLimitForToken);
  }, [show]);

  useDebounce(() => calculateAmountInUsd(), 1000, [amount]);

  useEffect(() => {
    calculateBorrowLimitForToken();
  }, [calculateBorrowLimitForToken]);

  return (
    <>
      <span
        onClick={() => {
          setShow(true);
        }}
      >
        <img src={item?.logo} alt="" />
        {item?.name}
      </span>
      <CommonModal
        show={show}
        onHide={onHide}
        title={item?.name}
        icon={
          <>
            <img src={item?.logo} alt="" />
          </>
        }
        className="borrow_repay_modal"
      >
        {tabsData ? (
          <div className="borrow_heading">
            <InputCustom
              type="text"
              value={amount}
              maxLength={25}
              onChange={handleAmountchange}
              autoFocus
            />
            <h3>
              80%
              <span>Limit</span>
            </h3>
          </div>
        ) : (
          <div className="widthrow">
            <div className="borrow_heading">
              <InputCustom
                type="text"
                value={amount}
                maxLength={25}
                onChange={handleAmountchange}
                autoFocus
              />
              <button
                onClick={() =>
                  setAmount(
                    item?.borrowBalance
                      ? divideBigNumber(
                          item?.borrowBalance[0]?.balance ?? "0",
                          String(item?.decimals).length - 1 || 1
                        )
                      : 0
                  )
                }
              >
                Max
              </button>
            </div>
            <p>
              To Supply or Repay {item?.name} Token to the Collatix Protocol,
              you need to enable it first.
            </p>
          </div>
        )}
        <Tab.Container defaultActiveKey="borrow">
          <Nav>
            <Nav.Item>
              <Nav.Link eventKey="borrow" onClick={() => setTabsData(true)}>
                Borrow
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="repay" onClick={() => setTabsData(false)}>
                Repay
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="borrow">
              <div className="borrow_values">
                <h3>Borrow Rates</h3>
                <ul className="borrow_list">
                  <li>
                    <h4>Borrow APY</h4>
                    <h6>
                      {" "}
                      {item?.apy ? (
                        item?.apy
                      ) : (
                        <Shimmer width={45} height={20} />
                      )}
                    </h6>
                  </li>
                </ul>
                <h3>Borrow Limit</h3>
                <ul>
                  <li>
                    <h4>Borrow Limit</h4>
                    <h6>
                      ${intToSuffixes(borrowRemaining + borrowUsed)}
                    </h6>
                  </li>
                  <li>
                    <h4>Borrow Remaining</h4>
                    <h6 title={borrowLimitForToken}>
                      ${intToSuffixes(borrowRemaining)}
                      <BlueRightIcon />
                      {intToSuffixes(borrowLimitForToken)} {item.symbol}
                    </h6>
                  </li>
                  <li>
                    <h4>Borrow Limit Used</h4>
                    <h6>{borrowLimitUsed ? borrowLimitUsed.toFixed(2) : 0}%</h6>
                  </li>
                </ul>
                <div className="progress_line">
                  <span
                    style={{
                      width: `${borrowLimitUsed ? borrowLimitUsed : 0}%`,
                    }}
                  ></span>
                </div>
              </div>
              <Button
                className="blue_btn"
                fluid
                onClick={() => {
                  handleBorrow();
                  onHide();
                }}
                disabled={
                  !walletAddress ||
                  !amount ||
                  Number(amount) === 0 ||
                  Number(borrowLimitForToken) < Number(amount)
                }
              >
                Borrow
              </Button>
              {!walletAddress && (
                <h4 className="warning-text">
                  Please connect wallet to continue.
                </h4>
              )}
              {walletAddress && (!amount || Number(amount) === 0) && (
                <h4 className="warning-text">
                  Please enter amount to continue.
                </h4>
              )}
              {walletAddress &&
                amount &&
                Number(borrowLimitForToken) < Number(amount) && (
                  <h4 className="warning-text">
                    The entered amount exceeds your available borrowing limit.
                  </h4>
                )}
              <div className="currently_borrow">
                <h5>Currently Borrowing</h5>
                <label>
                  {amount} {item.symbol}
                </label>
              </div>
              <div className="currently_borrow">
                <h5>Currently Borrowing(in USD)</h5>
                <label>${intToSuffixes(amountInUsd)}</label>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="repay">
              <div className="borrow_values">
                <h3>Borrow Rates</h3>
                <ul>
                  <li>
                    <h4>Borrow APY</h4>
                    <h6>
                      {" "}
                      {item?.apy ? (
                        item?.apy
                      ) : (
                        <Shimmer width={45} height={20} />
                      )}
                    </h6>
                  </li>
                </ul>
              </div>
              <Button
                className="blue_btn"
                fluid
                onClick={() => {
                  handleRepay();
                  onHide();
                }}
                disabled={
                  !walletAddress ||
                  !amount ||
                  Number(amount) === 0 ||
                  Number(amount) >
                    Number(
                      divideBigNumber(
                        item?.borrowBalance[0]?.balance ?? "0",
                        String(item?.decimals).length - 1 || 1
                      )
                    ) ||
                  Number(amount) >
                    Number(
                      divideBigNumber(
                        item?.userBalance,
                        String(item?.decimals).length - 1 || 1
                      )
                    )
                }
              >
                Repay
              </Button>
              {!walletAddress && (
                <h4 className="warning-text">
                  Please connect wallet to continue.
                </h4>
              )}
              {walletAddress && (!amount || Number(amount) === 0) && (
                <h4 className="warning-text">
                  Please enter amount to continue.
                </h4>
              )}
              {walletAddress &&
                amount &&
                Number(amount) >
                  Number(
                    divideBigNumber(
                      item?.userBalance,
                      String(item?.decimals).length - 1 || 1
                    )
                  ) &&
                Number(
                  divideBigNumber(
                    item?.userBalance,
                    String(item?.decimals).length - 1 || 1
                  )
                ) <
                  Number(
                    divideBigNumber(
                      item?.borrowBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  ) && (
                  <h4 className="warning-text">
                    The entered amount exceeds your wallet balance.
                  </h4>
                )}
              {walletAddress &&
                amount &&
                Number(amount) >
                  Number(
                    divideBigNumber(
                      item?.borrowBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  ) &&
                Number(
                  divideBigNumber(
                    item?.userBalance,
                    String(item?.decimals).length - 1 || 1
                  )
                ) >
                  Number(
                    divideBigNumber(
                      item?.borrowBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  ) && (
                  <h4 className="warning-text">
                    The entered amount exceeds your borrowed balance.
                  </h4>
                )}
              <div className="currently_borrow">
                <h5>Wallet Balance</h5>
                <label
                  title={String(
                    item?.borrowBalance
                      ? divideBigNumber(
                          item?.userBalance ?? "0",
                          String(item?.decimals).length - 1 || 1
                        )
                      : 0
                  )}
                >
                  {" "}
                  {item?.userBalance ? (
                    divideBigNumberWithSuffixes(
                      item?.userBalance ?? "0",
                      String(item?.decimals).length - 1 || 1,
                      true
                    )
                  ) : (
                    <Shimmer width={45} height={20} />
                  )}{" "}
                  {item?.symbol}
                </label>
              </div>
              <div className="currently_borrow">
                <h5>Borrow Balance</h5>
                <label
                  title={String(
                    item?.borrowBalance
                      ? divideBigNumber(
                          item?.borrowBalance[0]?.balance ?? "0",
                          String(item?.decimals).length - 1 || 1
                        )
                      : 0
                  )}
                >
                  {" "}
                  {item?.borrowBalance ? (
                    divideBigNumberWithSuffixes(
                      item?.borrowBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1,
                      true
                    )
                  ) : (
                    <Shimmer width={45} height={20} />
                  )}{" "}
                  {item?.symbol}
                </label>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default BorrowRepayModal;
