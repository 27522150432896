import axios from "axios";
import toaster from "../components/common/toast";
import { setIsAdmin } from "../redux/Slices/admin.slice";
import store from "../redux/Store";
import { API_HOST } from "../utils/Constant";
import { APIURL, RESPONSES } from "../utils/Utils";
import { encryptData, formatUrl } from "./common.service";
import * as Parallel from "async-parallel";
export const storeInstance = store;
axios.defaults.baseURL = API_HOST;

let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

/**AXIOS INTERCEPTOR */

axios.interceptors.request.use(
  async (config) => {
    const walletAddress = storeInstance.getState().user.walletAddress;
    const token = encryptData(walletAddress);
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },
  (error) => {
    return error;
  }
);

/**HANDLE AXIOS RESPONSE */
axios.interceptors.response.use(
  async (response) => {
    response.data = await response.data;

    // response.data = await decrypt(response.data);
    return response;
  },
  (error) => {
    if (!error.response) {
      toaster.error("Server not responding. Please try again later.");
    } else {
      return manageErrorConnection(error);
    }

    const originalRequest = error.config;
    failedQueue.push(originalRequest);
    if (error?.response?.status === 403) {
      processQueue(error, null);
    }
  }
);
/**HANDLE AXIOS ERROR */
export function manageErrorConnection(err: any) {
  if (err.response.status === 401) {
    store.dispatch(setIsAdmin(false));
    return;
  }
  if (
    err.response &&
    err.response.status >= 400 &&
    err.response.status <= 500
  ) {
    toaster.error(err.response.data.message);
    return Promise.reject(err);
  } else if (err.code === "ECONNREFUSED") {
    toaster.error("ECONNREFUSED");
    return "nevermind";
  } else {
    toaster.error(err);
    return Promise.reject(err);
  }
}

/**HANDLE AXIOS SUCCESS */
function handleSuccess(res: any) {
  if (res.status === RESPONSES.SUCCESS || res.status === RESPONSES.CREATED)
    res?.data?.message && toaster.success(res.data.message);
  else {
    res?.data?.message && toaster.info(res.data.message);
  }
}

/**METHOD FOR CALL API */
export const apiCallPost = (
  url: string,
  data: any,
  params = {},
  showtoaster = false
) =>
  new Promise((resolve) => {
    // let encParams = params ? { query: encryptData(JSON.stringify(params)) } : { query: '' };
    let encParams = params ? { query: JSON.stringify(params) } : { query: "" };
    axios
      // .post(formatUrl(url, encParams), { data: encryptData(data) })
      .post(formatUrl(url, encParams), data)

      .then((res) => {
        showtoaster && handleSuccess(res);
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err);
      });
  });

/**METHOD FOR SEND API */
export const apiCallGet = (
  url: string,
  params: any = {},
  showtoaster = false
) =>
  new Promise((resolve) => {
    // params.userAddress = "0x8d130dd8cc26025dC94Fb09AFdc6b6aE387ddFe7"
    // let encParams = params ? { query: encryptData(JSON.stringify(params)) } : { query: '' };
    axios
      // .get(formatUrl(url, encParams))
      .get(formatUrl(url, params))

      .then((res) => {
        showtoaster && handleSuccess(res);
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err);
      });
  });

/**METHOD FOR PUT API */
export const apiCallPut = (
  url: string,
  data: any,
  params = {},
  showtoaster = false
) =>
  new Promise((resolve) => {
    // let encParams = params
    //   ? { query: encryptData(JSON.stringify(params)) }
    //   : { query: "" };
    let encParams = params ? { query: JSON.stringify(params) } : { query: "" };
    axios
      // .put(formatUrl(url, encParams), { data: encryptData(data) })
      .put(formatUrl(url, encParams), data)

      .then((res) => {
        showtoaster && handleSuccess(res);
        resolve(res.data);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const openNewTab = (url: string, params: any) =>
  new Promise(() => {
    let walletAddress = storeInstance.getState().user.walletAddress;
    const token = encryptData(walletAddress);
    params.authorization = `Bearer ${token}`;
    console.log(
      "formatUrl(API_HOST + url, params)",
      formatUrl(API_HOST + url, params)
    );
    window.open(`${formatUrl(API_HOST + url, params)}`, "_blank");
  });

// Function for get x token Information

export const getXTokenInformation = async (items: any) => {
  const xtokenInfo = await Parallel.map(items, async (item: any) => {
   
    const tokenInfo:any = await apiCallGet(
      APIURL["GET_X_TOKEN_INFO"],
      { tokenAddress: item.address },
      false
    );
    let result = { ...item, tokenInfo: tokenInfo?.data };
    return result;
  });
  return xtokenInfo;
};
