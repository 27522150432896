import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../Types/reduxStateType/RootState";
import logo from "../../../assets/logo/logo.png";
import { callContractGetMethod } from "../../../redux/Actions/contract.action";
import { setIsAdmin } from "../../../redux/Slices/admin.slice";
import { customizeAddress } from "../../../services/common.service";
import { ROUTES } from "../../../utils/Utils";
import "../../common/adminHeader/AdminHeader.scss";
import Button from "../../common/button/Button";
import toast from "../../common/toast";
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const { open } = useWeb3Modal();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [adminAddress, setAdminAddress] = useState("");

  const getOwnerAddress = useCallback(async () => {
    const result: any = await dispatch(
      callContractGetMethod("admin", [], "comptroller", false)
    );

    if (result) {
      setAdminAddress(result);
    }
  }, [dispatch]);

  useEffect(() => {
    getOwnerAddress();
  }, [getOwnerAddress]);

  const login = () => {
    if (!walletAddress) {
      toast.success("Please connect wallet");
      return;
    }
    if (
      adminAddress &&
      walletAddress.toLowerCase() === adminAddress.toLowerCase() //chage here
    ) {
      dispatch(setIsAdmin(true));
      toast.success("Login Successfully");
      navigate(ROUTES.ADMIN_DASHBOARD);
    } else {
      toast.error("You are not the Admin");
    }
  };

  return (
    <div className="login">
      <div className="login_in gradient_box">
        <img src={logo} alt="" />
        <div className="login_btn_box">
          <Button className="login_btn" onClick={() => open()}>
            {walletAddress ? customizeAddress(walletAddress) : "Connect Wallet"}
          </Button>
          <Button className="login_btn" onClick={() => login()}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
