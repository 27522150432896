import { allowOnlyString } from "../../../services/common.service";
import "./InputCustom.scss";

const InputCustom = (props: any) => {
  const disabledCharacters = ["e", "E", "+", "-"];
  const onKeyDown = (e: any) => {
    if (props.disableDecimal) {
      disabledCharacters.push(".");
    }

    /** RESTRICT USER TO ENTER MORE THEN MAX LENGTH IN INPUT TYPE NUBER */
    return props.type === "number"
      ? (disabledCharacters.includes(e.key) ||
          (e.key !== "Backspace" &&
            props.maxLength &&
            e.target.value.length === props.maxLength)) &&
          e.preventDefault()
      : props.onlyChar
      ? !allowOnlyString(e.key) && e.preventDefault()
      : null;
  };

  return (
    <>
      <div className="custom_input">
        {props.label ? <label>{props.label}</label> : ""}
        <input
          type={props?.type}
          name={props.name}
          id={props.name}
          value={props?.value}
          onChange={props?.onChange}
          onKeyDown={onKeyDown}
          autoComplete="off"
          autoFocus={props?.autoFocus}
          maxLength={props?.maxLength}
          onFocus={props?.onFocus}
          placeholder={props?.placeholder}
          disabled={props?.disabled}
        />
        {props.error && <p className="error_msg">{props.error}</p>}
      </div>
    </>
  );
};

export default InputCustom;
