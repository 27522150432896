import * as Parallel from "async-parallel";
import { ethers } from "ethers";
import Web3 from "web3";
import XTokenDelegatorABI from "../abi/CDelegator.ABI.json";
import CetherABI from "../abi/Cether.ABI.json";
import ComptrollerABI from "../abi/Comptroller.ABI.json";
import DynamicABI from "../abi/Dynamic.ABI.json";
import ErrorABI from "../abi/Error.ABI.json";
import FactoryABI from "../abi/Factory.ABI.json";
import PriceOracleABI from "../abi/PriceOracle.ABI.json";
import WPIRMABI from "../abi/WPIRM.ABI.json";
import JRMABI from "../abi/JRM.ABI.json";
// import ErrorABI from "../abi/Error.ABI.json";
import {
  setBorrowApyDetails,
  setSupplyApyDetails,
} from "../redux/Slices/user.slice";
import store from "../redux/Store";
import {
  CHAIN_ID,
  COMPTROLLER_ADDRESS,
  FACTORY_ADDRESS,
  PRICE_ORACLE_ADDRESS,
  RPC_URL,
  ZERO_ADDRESS,
} from "../utils/Constant";
import {
  extractErrorMessage,
  getCustomErrorMsg,
  intToSuffixes,
} from "./common.service";

let web3Instance: any,
  comptrollerInstance: any,
  priceOracleInstance: any,
  factoryInstance: any;
export const storeInstance = store;

export const callWeb3 = (data: any) => {
  return new Promise(async (resolve) => {
    if (data) {
      /**CREATE INSTANCE WITH METAMASK */
      web3Instance = new Web3(data);
    } else if (
      window.ethereum &&
      Number(window.ethereum.networkVersion) === CHAIN_ID
    ) {
      /**CREATE INSTANCE WITH METAMASK */
      web3Instance = new Web3(window.ethereum);
    } else {
      /**CREATE INSTANCE WITH RPC */
      web3Instance = new Web3(RPC_URL);
    }
    resolve(web3Instance);
  });
};

function delay(time: any) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const createInstance = async (data: any = "") => {
  return delay(100).then(async () => {
    const web3: any = await callWeb3(data);
    /**CREATE CONTRACT INSTANCE WITH ABI */
    comptrollerInstance = new web3.eth.Contract(
      ComptrollerABI,
      COMPTROLLER_ADDRESS
    );
    priceOracleInstance = new web3.eth.Contract(
      PriceOracleABI,
      PRICE_ORACLE_ADDRESS
    );
    factoryInstance = new web3.eth.Contract(FactoryABI, FACTORY_ADDRESS);
    return true;
  });
};

/**SEND CONTRACT TYPE AND DYAMIC ADDRESS(OPTIONAL) FOR GET CONTRACT INSTANCE*/
const getContractInstance = async (
  provider: any = RPC_URL,
  contractType: string,
  dynamicAddress: string
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    switch (contractType) {
      case "comptroller":
        return comptrollerInstance
          ? resolve(comptrollerInstance)
          : createInstance(provider)
              .then(() => {
                resolve(comptrollerInstance);
              })
              .catch(reject);
      case "priceOracle":
        return priceOracleInstance
          ? resolve(priceOracleInstance)
          : createInstance(provider)
              .then(() => {
                resolve(priceOracleInstance);
              })
              .catch(reject);
      case "factory":
        return factoryInstance
          ? resolve(factoryInstance)
          : createInstance(provider)
              .then(() => {
                resolve(factoryInstance);
              })
              .catch(reject);
      case "cether":
        // eslint-disable-next-line no-case-declarations
        {
          const cEtherInstance = web3Instance
            ? await new web3Instance.eth.Contract(CetherABI, dynamicAddress)
            : await createInstance(provider).then(async () => {
                return await new web3Instance.eth.Contract(
                  CetherABI,
                  dynamicAddress
                );
              });
          resolve(cEtherInstance);
        }
        break;
        case "WPIRM":
        {
          const WPIRMInstance = web3Instance
            ? await new web3Instance.eth.Contract(WPIRMABI, dynamicAddress)
            : await createInstance(provider).then(async () => {
                return await new web3Instance.eth.Contract(
                  WPIRMABI,
                  dynamicAddress
                );
              });

          resolve(WPIRMInstance);
        }
        break;
      case "JRM":
        {
          const JRMInstance = web3Instance
            ? await new web3Instance.eth.Contract(JRMABI, dynamicAddress)
            : await createInstance(provider).then(async () => {
                return await new web3Instance.eth.Contract(
                  JRMABI,
                  dynamicAddress
                );
              });
          resolve(JRMInstance);
        }
        break;
      case "cerc20":
        // eslint-disable-next-line no-case-declarations
        {
          const cerc20Instance = web3Instance
            ? await new web3Instance.eth.Contract(
                XTokenDelegatorABI,
                dynamicAddress
              )
            : await createInstance(provider).then(async () => {
                return await new web3Instance.eth.Contract(
                  XTokenDelegatorABI,
                  dynamicAddress
                );
              });
          resolve(cerc20Instance);
        }
        break;
      case "dynamic":
        // eslint-disable-next-line no-case-declarations
        {
          const dynamicInstance = web3Instance
            ? await new web3Instance.eth.Contract(DynamicABI, dynamicAddress)
            : await createInstance(provider).then(async () => {
                return await new web3Instance.eth.Contract(
                  DynamicABI,
                  dynamicAddress
                );
              });
          resolve(dynamicInstance);
        }
        break;
      default:
        return null;
    }
  });
};

/**CALL CONTRACT GET METHODS. ALL PARAMS WILL BE DYNAMIC */
export const callGetMethod = async (
  method: string,
  data: any,
  contractType: string,
  dynamicAddress: string
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      /**GET SELECTED CONTRACT INSTANCE */
      const contract: any = await getContractInstance(
        "",
        contractType,
        dynamicAddress
      );
      if (contract.methods) {
        /**CALL GET METHOD */
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .call()
          .then((result: object) => {
            resolve(result);
          })
          .catch((error: Error) => {
            console.log(
              "error",
              error,
              method,
              data,
              contractType,
              dynamicAddress
            );
            if (method === "jumpMultiplierPerBlock" || method === "kink") {
              resolve(undefined);
            } else {
              reject(extractErrorMessage(error));
            }
        
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      // console.log("error", error, method);
      reject(extractErrorMessage(error));
    }
  });
};

/**CALL CONTRACT SEND METHODS. ALL PARAMS WILL BE DYNAMIC */
export const callSendMethod = async (
  provider: any,
  method: string,
  data: any,
  walletAddress: string,
  contractType: string,
  value: any,
  dynamicAddress: string,
  tokenAddress: string,
  isNative: boolean
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      /**CHECK WALLET IS CONNECTED */
      if (walletAddress === "") {
        reject(new Error("Please connect wallet"));
      }

      /**CREATE DATA FOR CALL SEND METHOD */
      const dataForSend: any = { from: walletAddress };

      /**CHECK IF WE NEED TO SEND VALUE IN SEND METHOD */
      if (value) {
        dataForSend.value = value;
      }
      /**GET SELECTED CONTRACT INSTANCE */
      const contract: any = await getContractInstance(
        provider,
        contractType,
        dynamicAddress
      );
      if ((method === "mint" || method === "repayBorrow") && !isNative) {
        const allowanceRes = await giveTokenAllowance({
          provider,
          walletAddress,
          tokenAddress: tokenAddress,
          contract: dynamicAddress,
          amount: data[0],
        });
        if (!allowanceRes) {
          return false;
        }
      }
      if (contract.methods) {
        /**ESTIMATE GAS FOR TRANSACTION */
        let web3: any = await callWeb3(provider);
        let gasLimit = await contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .estimateGas(dataForSend);
        dataForSend.gasPrice = await web3.eth.getGasPrice();
        dataForSend.gasLimit = gasLimit;

        /**CALL SEND METHOD */
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .send(dataForSend)
          .then((result: object) => {
            resolve(result);
          })
          .catch((error: Error) => {
            reject(extractErrorMessage(error));
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error: any) {
      console.log("error", error);
      let { errorMsg, decodedParams }: any = error?.data?.data
        ? decodeCustomError(error.data.data)
        : error;
      errorMsg =
        errorMsg === "Error" || errorMsg === undefined
          ? error
          : getCustomErrorMsg(errorMsg, Number(decodedParams));
      reject(extractErrorMessage(errorMsg));
    }
  });
};

/**FUNCTION FOR GIVE ALLOWANCE TO CONTRACT FOR TOKEN SPEND */
const giveTokenAllowance = async (data: any) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      /**GET SELECTED CONTRACT INSTANCE */
      let allowance: any = await callGetMethod(
        "allowance",
        [data.walletAddress, data.contract],
        "dynamic",
        data.tokenAddress
      );

      /**CHECK ALLOWANCE IS ALREADY GIVEN OR NOT */
      if (parseInt(allowance) < data?.amount) {
        /**SET ALLOWANCE VALUE AS 10**40 */
        const maxlimit: any = data?.amount;

        /**CALL SEND METHOD */
        const allowanceRes: any = await callSendMethod(
          data?.provider,
          "approve",
          [data.contract, maxlimit],
          data.walletAddress,
          "dynamic",
          null,
          data.tokenAddress,
          "",
          false
        );
        if (!allowanceRes.status) {
          return false;
        }
      }
      allowance = await callGetMethod(
        "allowance",
        [data.walletAddress, data.contract],
        "dynamic",
        data.tokenAddress
      );
      resolve(allowance);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTokenDetails = async (items: any, method: string) => {
  let walletAddress = storeInstance.getState().user.walletAddress;

  const addInfo = await Parallel.map(items, async (item: any) => {
    let data: any = item;
    if (walletAddress) {
      if (data.address !== ZERO_ADDRESS) {
        const userBalance: any = await callGetMethod(
          "balanceOf",
          [walletAddress],
          "dynamic",
          data.address
        );
        data = { ...data, userBalance: userBalance.toString() };
      } else {
        const userBalance: any = await new Web3(RPC_URL).eth.getBalance(
          walletAddress
        );
        data = { ...data, userBalance: userBalance.toString() };
      }
    } else {
      data = { ...data, userBalance: "0" };
    }
    let supplyBalance: any = await getAccountDetails(
      [data.xToken],
      "balanceOfUnderlying",
      ""
    );
    data = {
      ...data,
      supplyBalance: supplyBalance.length > 0 ? supplyBalance : {},
    };
    let borrowBalance: any = await getAccountDetails(
      [data?.xToken],
      "borrowBalanceCurrent",
      ""
    );
    data = {
      ...data,
      borrowBalance: borrowBalance.length > 0 ? borrowBalance : {},
    };
    let liquidity: any;
    if (data.address !== ZERO_ADDRESS) {
      liquidity = await callGetMethod(
        "balanceOf",
        [data?.xToken],
        "cerc20",
        data?.address
      );
    } else {
      liquidity = await new Web3(RPC_URL).eth.getBalance(data.xToken);
    }
    if (liquidity > 0) {
      const price: any = await callGetMethod(
        "getUnderlyingPrice",
        [data.xToken],
        "priceOracle",
        ""
      );
      liquidity =
        (Number(liquidity) / data.decimals) * (Number(price) / 10 ** 18);

    }
    data = {
      ...data,
      liquidity: liquidity > 0 ? "$" + intToSuffixes(liquidity) : 0,
    };
    const ratePerBlock: any = await callGetMethod(
      method,
      [],
      "cerc20",
      data.xToken
    );
    const blocksPerYear = 2102400;
    const ratePerBlockInDecimals = Number(ratePerBlock) / 10 ** 18;
    const apy = (1 + ratePerBlockInDecimals) ** blocksPerYear - 1;
    data = { ...data, apy: (apy * 100).toFixed(2).toString() + "%" };
    return { ...data, collateralInfo: data.collateralInfo };
  });
  return addInfo;
};

export const getXtokenInfo = async (items: any) => {
  const addInfo = await Parallel.map(items, async (item: any) => {
    //  console.log(item.xToken,"xToken..............")
    let borrowLimt: any = await callGetMethod(
      "borrowCaps",
      [item.xToken],
      "comptroller",
      ""
    );

    let marketReserve: any = await callGetMethod(
      "markets",
      [item.xToken],
      "comptroller",
      ""
    );
    item = {
      ...item,
      borrowCaps: borrowLimt,
      markets: marketReserve?.collateralFactorMantissa,
    };
    return item;
  });

  return addInfo;
};

export const getLiquidateAccountDetails = async (items: any) => {
  const addInfo = await Parallel.map(items, async (item: any) => {
    //  console.log(item.xToken,"xToken..............")
    let borrowLimt: any = await callGetMethod(
      "getAccountLiquidity",
      [item.userAddress],
      "comptroller",
      ""
    );
    item = { ...item, outputs: borrowLimt };
    return item;
  });

  return addInfo;
};

export const getBorrowAndSupplyDetails = async (
  items: any,
  userAddress: string
) => {
  const addInfo = await Parallel.map(items, async (item: any) => {
    let borrowInfo: any = await callGetMethod(
      "borrowBalanceCurrent",
      [userAddress],
      "cerc20",
      item
    );

    let supplyInfo: any = await callGetMethod(
      "balanceOfUnderlying",
      [userAddress],
      "cerc20",
      item
    );

    let result = {
      address: item,
      borrowInfo: borrowInfo,
      supplyInfo: supplyInfo,
    };
    return result;
  });

  return addInfo;
};

// Working on it
export const getInterestRateModalDetail = async (items: any) => {
  // console.log(items,".............................")
  const addInfo = await Parallel.map(items, async (item: any) => {
    // console.log(item,".....,,.,.,.,.,.,.,.,")
    let irmAddress: any = await callGetMethod(
      "interestRateModel",
      [],
      "cerc20",
      item.xToken
    );
    // console.log('irmAddress', irmAddress)
    let baseRatePerBlock: any = await callGetMethod(
      "baseRatePerBlock",
      [],
      "JRM",
      irmAddress
    );
    console.log('baseRatePerBlock', baseRatePerBlock)
    let blocksPerYear: any = await callGetMethod(
      "blocksPerYear",
      [],
      "JRM",
      irmAddress
    );
    let multiplierPerBlock: any = await callGetMethod(
      "multiplierPerBlock",
      [],
      "JRM",
      irmAddress
    );
    let jumpMultiplierPerBlock: any = await callGetMethod(
      "jumpMultiplierPerBlock",
      [],
      "JRM",
      irmAddress
    );
     let kink: any = await callGetMethod("kink", [], "JRM", irmAddress);
    item = {
      ...item,
      irmAddress: irmAddress,
      baseRatePerBlock: baseRatePerBlock,
      blocksPerYear: blocksPerYear,
      multiplierPerBlock: multiplierPerBlock,
      jumpMultiplierPerBlock: jumpMultiplierPerBlock,
      kink: kink,
    };

    return item;
  });

  return addInfo;
};

export const getAccountDetails = async (
  items: any,
  method1: string,
  method2: string
) => {
  let walletAddress = storeInstance.getState().user.walletAddress;
  const apyResult: any = [];
  const accountInfo = await Parallel.map(items, async (item: any) => {
    let data: any = [];
    if (walletAddress) {
      const userBalance: any = await callGetMethod(
        method1,
        [walletAddress],
        "cerc20",
        item
      );
      let balance = userBalance;
      data = {
        ...data,
        balance: balance ?? 0,
      };
      const price: any = await callGetMethod(
        "getUnderlyingPrice",
        [item],
        "priceOracle",
        ""
      );
      if (method2 !== "") {
        const ratePerBlock: any = await callGetMethod(
          method2,
          [],
          "cerc20",
          item
        );
        const blocksPerYear = 2102400;
        const ratePerBlockInDecimals = Number(ratePerBlock) / 10 ** 18;
        const apy = (1 + ratePerBlockInDecimals) ** blocksPerYear - 1;
        apyResult.push(apy * 100);
      }
      let balanceInUsd = (Number(userBalance) * Number(price)) / 10 ** 18;
      data = {
        ...data,
        balanceInUsd: balanceInUsd ?? 0,
      };
      return { ...data };
    } else {
      return data;
    }
  });
  if (method2 === "supplyRatePerBlock") {
    store.dispatch(setSupplyApyDetails(apyResult));
  } else if (method2 === "borrowRatePerBlock") {
    store.dispatch(setBorrowApyDetails(apyResult));
  }
  return accountInfo;
};

// Function to dynamically decode custom errors
const decodeCustomError = (errorData: any) => {
  const iface: any = new ethers.Interface(ErrorABI);
  // Extract the error signature (first 4 bytes of the data)
  const errorSignature = errorData?.slice(0, 10); // "0x" + first 8 hex characters
  // console.log("errorSignature", errorSignature);
  // Get the error description from ABI (it will contain error name and types)
  const errorFragment: any = iface.getError(errorSignature);

  // console.log("errorFragment", errorFragment);
  if (errorFragment) {
    const errorMsg = errorFragment.name;
    const decodedParams = iface.decodeErrorResult(errorFragment, errorData);
    console.log("first", errorMsg, decodedParams);
    return { errorMsg, decodedParams };
  } else {
    return "Unknown error occurred";
  }
};
