import { useFormik } from "formik";
import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import * as Yup from "yup";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import {
  convertWithDecimal
} from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import toast from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./InterestRateModal.scss";

const InterestRateModal = (props: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const formik = useFormik({
    initialValues: {
      baseRatePerYear: 0,
      multiplierPerYear: 0,
      jumpMultiplierPerYear: 0,
      kink: 0,
    },
    validationSchema: Yup.object({
      baseRatePerYear: Yup.number()
        .required("Required Field*")
      .max(10, "Value must not be greater than 10")
        .moreThan(0, "Value should be greater than 0"),
      multiplierPerYear: Yup.number()
        .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")

        .moreThan(0, "Value should be greater than 0"),
      jumpMultiplierPerYear: Yup.number()
        .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")

        .moreThan(0, "Value should be greater than 0"),
      kink: Yup.number()
        .required("Required Field*")
      .max(100, "Value must not be greater than 100 %")

        .moreThan(0, "Value should be greater than 0"),
    }),
    onSubmit: (values) => {
      createInterestRateModel(values);
    },
  });

  const createInterestRateModel = async (values: any) => {
    const uniqueValue = numberTo32Bytes();
    let provider = await connector?.getProvider();
  
      setShowConfirmation(true);
      setModalKey("reserveFactor");
      handleClose();
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "createInterestRateModel",
          [
            convertWithDecimal(values?.baseRatePerYear, 16),
            convertWithDecimal(values?.multiplierPerYear, 16),
            convertWithDecimal(values.jumpMultiplierPerYear, 16),
            convertWithDecimal(values.kink, 16),
            uniqueValue,
          ],
          walletAddress,
          "factory",
          "reserveFactor"
        )
      );
      if (result?.status) {
        if (result?.events?.InterestRateModelcreated) {
          setInterestRateModule(
            result.events.InterestRateModelcreated.returnValues[0]
          );
        }
      } else if (result === undefined) {
        setModalKey("error");
      }
   
  };
  const setInterestRateModule = async (_contractAdd: string) => {
    setShowConfirmation(true);
    setModalKey("reserveFactor");
    let provider = await connector?.getProvider();
  
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_setInterestRateModel",
          [_contractAdd],
          walletAddress,
          "cerc20",
          "reserveFactor",
          "",
          props?.data
        )
      );
      if (result?.status) {
        
        toast.success("Transaction successfull");
      } else if (result === undefined) {
        setModalKey("error");
      }
   
  };
  function numberTo32Bytes(): string {
    let hex = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
    let paddedHex = hex.padEnd(64, "0");
    return "0x" + paddedHex;
  }
  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };

  return (
    <>
      <Button className="setCollateralBtn" onClick={() => setShow(true)}>
        Update
      </Button>

      <CommonModal
        show={show}
        onHide={handleClose}
        title={props.title}
        className="confirm_logout"
        size="sm"
      >
        <h4>{props.content}</h4>
        <form onSubmit={formik.handleSubmit}>
          <InputCustom
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={"Base rate per year (in%)"}
            value={
              formik.values.baseRatePerYear ? formik.values.baseRatePerYear : ""
            }
            type="number"
            maxLength={2}
            placeholder={"Enter Value here"}
            name="baseRatePerYear"
            error={
              formik.touched.baseRatePerYear && formik.errors.baseRatePerYear
            }
          />
          <InputCustom
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={"Multiplier Per Year (in%)"}
            value={
              formik.values.multiplierPerYear
                ? formik.values.multiplierPerYear
                : ""
            }
            type="number"
            maxLength={3}
            placeholder={"Enter Value here"}
            name="multiplierPerYear"
            error={
              formik.touched.multiplierPerYear &&
              formik.errors.multiplierPerYear
            }
          />
          <InputCustom
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={"Jmp Multiplier Per Year(in%)"}
            value={
              formik.values.jumpMultiplierPerYear
                ? formik.values.jumpMultiplierPerYear
                : ""
            }
            type="number"
            maxLength={3}
            placeholder={"Enter Value here"}
            name="jumpMultiplierPerYear"
            error={
              formik.touched.jumpMultiplierPerYear &&
              formik.errors.jumpMultiplierPerYear
            }
          />
          <InputCustom
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.kink ? formik.values.kink : ""}
            type="number"
            maxLength={3}
            placeholder={"Enter Value here"}
            label={"Kink (in%)"}
            name="kink"
            error={formik.touched.kink && formik.errors.kink}
          />

          <div className="btn_pair">
            <Button type="submit">Confirm</Button>
            <Button onClick={handleClose} className="border_btn">
              Cancel
            </Button>
          </div>
        </form>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default InterestRateModal;
