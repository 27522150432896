import { Form, Formik } from "formik";
import { Dispatch, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../redux/Actions/contract.action";
import {
  convertWithDecimal,
  getFailureErrorMsg,
  handleBigNumbers,
} from "../../../services/common.service";
import { RootState } from "../../../Types/reduxStateType/RootState";
import { Yup } from "../../../utils/Utils";
import "../../common/adminHeader/AdminHeader.scss";
import Button from "../../common/button/Button";
import Checkbox from "../../common/checkbox/Checkbox";
import CommonHeading from "../../common/commonHeading/CommonHeading";
import InputCustom from "../../common/commonInputs/InputCustom";
import ConfirmTransactionModal from "../../common/modals/confirmTransactionModal/ConfirmTransactionModal";
import toaster from "../../common/toast";
import "./Setting.scss";
const Setting = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");

  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [transferPauseStatus, setTransferPauseStatus] =
    useState<boolean>(false);
  const [seizePauseStatus, setSeizePauseStatus] = useState<boolean>(false);

  const getTransferPauseStatus = async () => {
    try {
      const result: any = await dispatch(
        callContractGetMethod(
          "transferGuardianPaused",
          [],
          "comptroller",
          false
        )
      );
      if (result) {
        setTransferPauseStatus(result);
      }
    } catch (error) {
      console.log("Errror occurred in getting transfer pause status", error);
    }
  };

  const getSeizePauseStatus = async () => {
    try {
      const result: any = await dispatch(
        callContractGetMethod("seizeGuardianPaused", [], "comptroller", false)
      );
      if (result) {
        setSeizePauseStatus(result);
      }
    } catch (error) {
      console.log("Error occurred in getting transfer pause status", error);
    }
  };
  
  useEffect(() => {
    getTransferPauseStatus();
    getSeizePauseStatus();
  }, []);

  const priceOracleInitialValues = {
    newOracleAddress: "",
    confirmNewOracleAddress: "",
  };
  const priceOracleSchema = Yup.object({
    newOracleAddress: Yup.string().required("Required Field*"),
    confirmNewOracleAddress: Yup.string()
      .trim()
      .required("Required Field*")
      .test(
        "address-match",
        "The provided addresses do not match. Please verify.",
        function (value: string, context: any) {
          return value === context.parent.newOracleAddress;
        }
      ),
  });
  const setNewPriceOracle = async (values: any) => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("oracle");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setPriceOracle",
        [values.newOracleAddress],
        walletAddress,
        "comptroller",
        "oracle"
      )
    );
    if (result?.status) {
      // handleClose()
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");

        toaster.error(errorMSg);
      } else {
        toaster.success("Price Oracle address updated successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const closeFactorInitialValues = {
    closeFactor: "",
  };
  const closeFactorSchema = Yup.object({
    closeFactor: Yup.number()
      .required("Required Field*")
      .min(0, "Value should not be lesser than 0")
      .max(100, "Value should not be greater than 100"),
  });
  const setCloseFactor = async (values: any) => {
    let provider = await connector?.getProvider();
    setShowConfirmation(true);
    setModalKey("closeFactor");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setCloseFactor",
        [convertWithDecimal(values.closeFactor, 16)],
        walletAddress,
        "comptroller",
        "closeFactor"
      )
    );
    if (result?.status) {
      // handleClose()
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        toaster.success("Close Factor updated successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  //Set Liquidation Incentive
  const liquidationIncentiveInitialValues = {
    newLiquidationIncentive: "",
  };
  const liquidationIncentiveSchema = Yup.object({
    newLiquidationIncentive: Yup.number()
      .required("Required Field*")
      .min(0, "Value should be lesser than 0")
      .max(100, "Value should not be greater than 100"),
  });
  const setLiquidationIncentive = async (values: any) => {
    let provider = await connector?.getProvider();

    setShowConfirmation(true);
    setModalKey("incentive");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setLiquidationIncentive",
        [
          handleBigNumbers(
            convertWithDecimal(1, 18),
            convertWithDecimal(values.newLiquidationIncentive, 16)
          ),
        ],
        walletAddress,
        "comptroller",
        "incentive"
      )
    );
    if (result?.status) {
      // handleClose()
      if (result?.events?.Failure) {
        console.log("first", result?.events?.Failure);
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        toaster.success("Liquidation Incentive updated successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const setTransferPaused = async () => {
    let provider = await connector?.getProvider();

    setShowConfirmation(true);
    setModalKey("pause");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setTransferPaused",
        [!transferPauseStatus],
        walletAddress,
        "comptroller",
        "pause"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        setTimeout(() => {
          getTransferPauseStatus();
        }, 1000);
        toaster.success("Transfer paused successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const setSeizePaused = async () => {
    let provider = await connector?.getProvider();

    setShowConfirmation(true);
    setModalKey("pause");
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "_setSeizePaused",
        [!seizePauseStatus],
        walletAddress,
        "comptroller",
        "pause"
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        setModalKey("error");
        toaster.error(errorMSg);
      } else {
        setTimeout(() => {
          getSeizePauseStatus();
        }, 1000);
        toaster.success("Seize paused successfully");
      }
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  return (
    <div className="setting">
      <CommonHeading title="Settings" />
      <>
        <Formik
          initialValues={priceOracleInitialValues}
          validationSchema={priceOracleSchema}
          onSubmit={setNewPriceOracle}
        >
          {(formik) => (
            <div className="setting_in ">
              <h3>Price Oracle</h3>
              <Form>
                <div className="gradient_box">
                  <Row>
                    <Col lg={6} xl={6} md={12} sm={6}>
                      <InputCustom
                        placeholder="Enter Price Oracle Address"
                        label="Price Oracle Address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newOracleAddress}
                        maxLength={70}
                        name="newOracleAddress"
                        error={
                          <>
                            {formik.errors.newOracleAddress &&
                            formik.touched.newOracleAddress ? (
                              <>{formik.errors.newOracleAddress}</>
                            ) : null}
                          </>
                        }
                      />
                    </Col>
                    <Col lg={6} xl={6} md={12} sm={6}>
                      <InputCustom
                        placeholder="Enter Price Oracle Address"
                        label="Confirm Price Oracle Address"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmNewOracleAddress}
                        maxLength={70}
                        name="confirmNewOracleAddress"
                        error={
                          <>
                            {formik.errors.confirmNewOracleAddress &&
                            formik.touched.confirmNewOracleAddress ? (
                              <>{formik.errors.confirmNewOracleAddress}</>
                            ) : null}
                          </>
                        }
                      />
                    </Col>
                    <Col lg={12}>
                      <Button
                        className="submit_btn "
                        // buttonLoader={"collateralFactor"}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          )}
        </Formik>
        <div className="mid_setting">
          <Row>
            <Col lg={6} xl={4} md={12} sm={6}>
              <h3> Close Factor</h3>
              <div className="gradient_box">
                <Formik
                  initialValues={closeFactorInitialValues}
                  validationSchema={closeFactorSchema}
                  onSubmit={setCloseFactor}
                >
                  {(formik) => (
                    <>
                      <Form>
                        <div className="field  w-100 ">
                          <InputCustom
                            placeholder="Enter Close Factor Percentage"
                            label="Close Factor(in%)"
                            type="number"
                            maxLength={3}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="closeFactor"
                            error={
                              <>
                                {formik.errors.closeFactor &&
                                formik.touched.closeFactor ? (
                                  <>{formik.errors.closeFactor}</>
                                ) : null}
                              </>
                            }
                          />
                          <Button
                            className="submit_btn "
                            // buttonLoader={"collateralFactor"}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Col>
            <Col lg={6} xl={4} md={12} sm={6}>
              <h3>Liquidation Incentive</h3>
              <div className="gradient_box">
                <Formik
                  initialValues={liquidationIncentiveInitialValues}
                  validationSchema={liquidationIncentiveSchema}
                  onSubmit={setLiquidationIncentive}
                >
                  {(formik) => (
                    <>
                      <Form>
                        <div className="field w-100 ">
                          <InputCustom
                            placeholder="Enter Liquidation Incentive Percentage"
                            label="Liquidation Incentive(in%)"
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="newLiquidationIncentive"
                            maxLength={3}
                            error={
                              <>
                                {formik.errors.newLiquidationIncentive &&
                                formik.touched.newLiquidationIncentive ? (
                                  <>{formik.errors.newLiquidationIncentive}</>
                                ) : null}
                              </>
                            }
                          />
                          <Button
                            className="submit_btn "
                            // buttonLoader={"collateralFactor"}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={6} xl={4} md={12} sm={6}>
            <div className="gradient_box">
              <div className="check_box">
                <h3>Transfer Pause</h3>
                <div className="field ">
                  <Checkbox
                    className="checkbox"
                    onChange={() => {
                      setTransferPaused();
                    }}
                    checked={transferPauseStatus}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} xl={4} md={12} sm={6}>
            <div className="gradient_box">
              <div className="check_box">
                <h3>Seize Pause</h3>
                <div className="field">
                  <Checkbox
                    className="checkbox"
                    onChange={() => {
                      setSeizePaused();
                    }}
                    checked={seizePauseStatus}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </div>
  );
};

export default Setting;
