import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import CommonModal from "../../commonModal/CommonModal";
import toast from "../../toast";
import "./MintPauseModal.scss";
import { getFailureErrorMsg } from "../../../../services/common.service";
import toaster from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";

const MintPauseModal = ({ data }: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const [show, setShow] = useState<boolean>(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const handleClose = () => {
    setShow(false);
  };

  const setMintPaused = async () => {
    let provider = await connector?.getProvider();
  
      setShowConfirmation(true);
      setModalKey("mintPaused");
      handleClose();
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_setMintPaused",
          [data.xToken, !data.mintPauseState],
          walletAddress,
          "comptroller",
          "mintPaused"
        )
      );
      if (result?.status) {
        if (result?.events?.Failure) {
          let errorMSg = getFailureErrorMsg(
            Number(result?.events?.Failure?.returnValues?.info)
          );
          toaster.error(errorMSg);
          setModalKey("error");
        } else {
          toast.success("Mint Paused Successfully");
        }
      } else if (result == undefined) {
        setModalKey("error");
      }
    
  };

  return (
    <>
      {" "}
      <Checkbox
        className="checkbox"
        onChange={() => {
          setShow(true);
        }}
        checked={data?.mintPauseState}
      />
      <CommonModal
        show={show}
        onHide={handleClose}
        title=""
        className="confirm_logout"
      >
        <h4>{`Are you sure you want to pause mint for ${data?.name}?`}</h4>
        <div className="btn_pair">
          <Button onClick={() => setMintPaused()}>Confirm</Button>
          <Button onClick={handleClose} className="border_btn">
            Cancel
          </Button>
        </div>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={true}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default MintPauseModal;
