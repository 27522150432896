import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import { getFailureErrorMsg } from "../../../../services/common.service";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import Button from "../../button/Button";
import Checkbox from "../../checkbox/Checkbox";
import CommonModal from "../../commonModal/CommonModal";
import { default as toast, default as toaster } from "../../toast";
import "./BorrowPauseModal.scss";

const BorrowPauseModal = ({ data }: any) => {
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const [show, setShow] = useState<boolean>(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();

  const handleClose = () => {
    setShow(false);
  };

  const setborrowPaused = async () => {
    let provider = await connector?.getProvider();
    
      let result: any = await dispatch(
        callContractSendMethod(
          provider,
          "_setBorrowPaused",
          [data.xToken, !data.borrowPauseState],
          walletAddress,
          "comptroller",
          "borrowPaused"
        )
      );
      if (result?.status) {
        
        if(result?.events?.Failure){
          let errorMSg = getFailureErrorMsg(Number(result?.events?.Failure?.returnValues?.info))
          toaster.error(errorMSg)
         
        }else{
          toast.success("Transaction successfull");
        }
      }
   
  };

  return (
    <>
      <Checkbox
        className="checkbox"
        onChange={() => {
          setShow(true);
        }}
        checked={data?.borrowPauseState}
      />
      <CommonModal
        show={show}
        onHide={handleClose}
        title=""
        className="confirm_logout"
      >
        <h4>{`Are you sure you want to pause borrow for ${data?.name}?`}</h4>
        <div className="btn_pair">
          <Button onClick={() => setborrowPaused()}>Confirm</Button>
          <Button onClick={handleClose} className="border_btn">
            Cancel
          </Button>
        </div>
      </CommonModal>
    </>
  );
};

export default BorrowPauseModal;
